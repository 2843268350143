import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomePageComponent } from "../app/home-page/home-page.component";
import { HomePageEnComponent } from "../app/translate/en/home-page-en/home-page-en.component";

const routes: Routes = [
  { path: "", component: HomePageComponent, pathMatch: "full" },
  { path: "home", component: HomePageComponent },
  { path: "en", component: HomePageEnComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
