import { Component, OnInit } from '@angular/core';
import { HomePageComponent } from '../home-page/home-page.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  fullpage_api: any;
  
  getRef(fullPageRef) {
    this.fullpage_api = fullPageRef;
  }

  constructor(private HomePageComponent: HomePageComponent) { }

  ngOnInit(): void {
  }

}
