import { Component } from '@angular/core';
// import * as $ from 'jquery'  when need jQuery

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent {  

  config: any;
  fullpage_api: any;
  selectedIndex = -1;
  showMenu:boolean = false;
  showBack:boolean = false;
  showBack2:boolean = false;
  showBack3:boolean = false;
  showBack4:boolean = false;
  showBack5:boolean = false;
  showBack6:boolean = false;
  showBack7:boolean = false;
  
  getRef = (fullPageRef) => {
    this.fullpage_api = fullPageRef;
  }

  openMenu = () =>{
    this.showMenu = !this.showMenu;
  }

  contactUs = () => {
    this.fullpage_api.moveSlideLeft();
    this.fullpage_api.silentMoveTo('kontakt', 0);
  }

  closeMenu = () => {
    this.showMenu = false;
  }

  toggleDisplay = (index) => {
    this.selectedIndex = index;

  }  

  constructor() { 
    // for more details on config options please visit fullPage.js docs
    this.config = {

      // fullpage options
      licenseKey: 'C112CF34-0E7D49CA-AC703EC5-CAE7E88E',
      anchors: ['home', 'pod', 'yms', 'operatorzy-logistyczni', 'producenci-i-dystrybutorzy', 'firmy-transportowe', 'o-firmie', 'kontakt'],
      menu: '#menu',

      // Scrolling
      css3: true,
      scrollingSpeed: 900,
      touchSensitivity: 25,

      // Design
      controlArrows: false,
      paddingTop: '0',
      paddingBottom: '0',
      fixedElements: '#header, .footer',
      scrollOverflow: true,
      verticalCentered: false,

      // Accessibiity
      keyboardScrolling: true,
      recordHistory: false,

      //Custom selectors
      sectionSelector: '.section',
      slideSelector: '.slide',

      lazyLoading: false,

      // fullpage callbacks
      afterRender: function () {},
      // SECTION RENDER
      afterResize: () => {
        console.log("After resize");

      },
      afterLoad: (origin, destination, direction) => {

        // Animation header
        let section = destination.item;
        const title = section.querySelector('h3');
        const desc = section.querySelector('section');
        const hero = section.querySelector('small.hero');

        title.classList.add('showTitle');
        desc.classList.add('desc');


        if(destination.index > 0) {

          hero.classList.add('showHero');
        }

        if(destination.index === 7) {
          hero.classList.remove('showHero');
        }
      },
      onLeave: (origin, destination, direction) => {

        // Clear Animation header
        let section = destination.item;
        const title = section.querySelector('h3');
        const desc = section.querySelector('section');
        const hero = section.querySelector('small.hero');

        title.classList.remove('showTitle');
        desc.classList.remove('desc');


        if(destination.index > 0 ) {

          hero.classList.remove('showHero');
        }

      },
      // SLIDE RENDER
      afterSlideLoad: (section, origin, destination, direction) => {
      

        if(destination.index === 1) {

          this.fullpage_api.setAllowScrolling(false, 'all');

        } else {

          this.fullpage_api.setAllowScrolling(true);
        }
        
      },
      onSlideLeave: (section, origin, destination, direction) => {

      }
    };
  } 

}
