<div class="floatCloseButton" (click)="fullpage_api.moveSlideLeft();">
    <div class="icon">
        <img src="assets/m4.svg">
    </div>
    <div class="mt-10">Back</div>
</div>
<div fullpage id="fullpage" [options]="config" (ref)="getRef($event)">
    <div class="section coverImg" id="screen1">
        <div class="slide">
            <div class="topWrapper">
                <div id="logoMenu" class="flex flexItemCenter saLogo px-15 pxD-35 ">
                    <div class="logoRotate2 mr-5">
                        <img src="../../../../assets/logo_icon.svg">
                    </div>
                    <div class="logoText">
                        <img src="../../../../assets/sa_text2.svg">
                    </div>
                </div>
                <div id="burgerMenu" class="flexRight px-15 pxD-35 ">
                    <div class="burger" (click)="openMenu()">
                        <div class="line1"></div>
                        <div class="short line2"></div>
                        <div class="line3"></div>
                    </div>
                </div>
            </div>
            <div class="gridEnd desktopHeaderLineBox ">
                <div class="px-15 desktopPx-35">
                    <h3 class="mt-0 mb-20"><span>M</span>OBILE <span>L</span>OGISTICS<br> <span>M</span>ANAGEMENT
                    </h3>
                    <section class="moreArrow" (click)="fullpage_api.moveTo('home', 1);">
                        B2B delivery process and yard operations managed and optimized real-time. ​
                        <span class="arrowButton"><img src="../../../../assets/arrowN.svg"></span>
                    </section>
                </div>
                <!-- Scroll Mouse -->
                <div class="scroll-downs">
                    <div class="mousey">
                        <div class="scroller"></div>
                    </div>
                    Scroll Down
                </div>
                <div class="shortStory halfGrid">
                    <div class="halfGrid">
                        <div>
                            <div class="number">
                                <div class="topNumber">235</div>
                                <div class="text">Thousand</div>
                            </div>
                            <div class="descNumber">
                                Number of drivers served by our<br> systems in Europe last year. ​
                            </div>
                        </div>
                        <div>
                            <div class="number">
                                <div class="topNumber">2.7</div>
                                <div class="text">Million</div>
                            </div>
                            <div class="descNumber">
                                LTL and FTL deliveries managed by<br> SmarterPOD in last 12 months.​
                            </div>
                        </div>
                    </div>
                    <div class="map">
                        <div class="mb-20 mapTitle">International Clients</div>
                        <div class="description mb-20">
                            We work for big logistics. We serve both, international <br> logistics operators and
                            manufacturers handling<br> the logistics internally.
                        </div>
                        <button class="moreInfo" (click)="fullpage_api.moveTo('about-us', 1);">Learn more​</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="slide">
            <div class="slidePanel">
                <section class="gridWraperLayout desctopContent mb-10 pt-15 pb-15">
                    <div class="logo2">
                        <img src="assets/logo_ba.svg">
                    </div>
                    <div class="burger2" (click)="fullpage_api.moveTo('home', 0);">
                        <div class="line1"></div>
                        <div class="short line2"></div>
                        <div class="line3"></div>
                    </div>
                </section>
                <section class="sectionImage mb-20" id="podTopImg1"></section>
                <div class="desctopContent px-15">
                    <!-- Wstęp -->
                    <section class="headerLine mb-20">
                        <div class="iconImg"></div>
                        <h3>Mobile Logistics Management</h3>
                    </section>
                    <article class="mb-20 mtbD-30">
                        We deliver proof of delivery and yard management class systems enabling real time, digital
                        management of delivery and yard operations. We lead our clients through digital transformation.
                    </article>
                    <article class="mb-20 mtbD-30">
                        <b>Our strategic focus is B2B mobile logistics:</b>
                    </article>
                    <div class="mb-20 mtbD-30">
                        <ul class="logicList">
                            <li class="gridLine">
                                <div class="iconBox2">
                                    <div class="icon"><img src="assets/icon11.svg"></div>
                                </div>
                                <div class="text">
                                    We standardize, automate and optimize delivery and yard processes.
                                </div>
                            </li>
                            <li class="gridLine">
                                <div class="iconBox2">
                                    <div class="icon"><img src="assets/icon12.svg"></div>
                                </div>
                                <div class="text">
                                    We support the drivers during the delivery. We lead them through the process, save
                                    service time with automated data input., increasing their labor efficiency and
                                    reducing delivery and pick-up errors
                                </div>
                            </li>
                            <li class="gridLine">
                                <div class="iconBox2">
                                    <div class="icon"><img src="assets/icon13.svg"></div>
                                </div>
                                <div class="text">
                                    We support logistics park employees, during yard operations execution. We automate
                                    notifications and gate operations. ​
                                </div>
                            </li>
                            <li class="gridLine">
                                <div class="iconBox2">
                                    <div class="icon"><img src="assets/icon14.svg"></div>
                                </div>
                                <div class="text">
                                    We support dispatch team with real-time control and management tools, Instant
                                    customer communications, re-assignment of ad-hoc pick-ups or deliveries.​
                                </div>
                            </li>
                            <li class="gridLine">
                                <div class="iconBox2">
                                    <div class="icon"><img src="assets/icon15.svg"></div>
                                </div>
                                <div class="text">
                                    Our systems foresee and prevents potential delays or errors.​
                                </div>
                            </li>
                            <li class="gridLine">
                                <div class="iconBox2">
                                    <div class="icon"><img src="assets/icon16.svg"></div>
                                </div>
                                <div class="text">
                                    We support management team with automated, Real-time and historic performance
                                    reports, SLA and KPIs, ​
                                </div>
                            </li>
                            <li class="gridLine">
                                <div class="iconBox2">
                                    <div class="icon"><img src="assets/icon17.svg"></div>
                                </div>
                                <div class="text">
                                    We provide real-time delivery or pick-up status to all of the parties involved in
                                    supply operations. ​
                                </div>
                            </li>
                        </ul>
                    </div>
                    <article class="mb-20 mtbD-30">
                        We increase resource productivity, work efficiency and lower unit delivery cost. ​
                    </article>
                </div>
                <!-- Stopka -->
                <section class="footertop px-15">
                    <div class="desctopContent">
                        <div class="mb-20 mt-10">
                            <b style="font-size: 18px;">Get in touch with us.</b>
                            <br><br>
                            Find out how we can improve the efficiency of your business. ​
                        </div>
                        <div class="mb-20">
                            <button class="contactBtn accentBg" (click)="contactUs()">
                                Contact Us​
                            </button>
                        </div>
                    </div>
                </section>
                <!-- podziel się -->
                <section class="footerMiddle pt-10 px-15">
                    <div class="desctopContent">
                        <div class="flex flexItemCenter mb-10">
                            <div class="icon"><img src="assets/m1.svg"></div>
                            <div class="ml-20">Download PDF</div>
                        </div>
                        <div class="flex flexItemCenter mb-10">
                            <div class="icon"><img src="assets/m2.svg"></div>
                            <div class="ml-20">Share</div>
                        </div>
                        <div class="flex flexItemCenter backButton">
                            <div class="icon"><img src="assets/m3.svg"></div>
                            <div class="ml-20" (click)="fullpage_api.moveTo('home', 0);">Back</div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
    <!-- POD -->
    <div class="section coverImg" id="screen2">
        <!-- Zajawka POD -->
        <div class="slide">
            <div class="topWrapper">
                <div id="logoMenu" class="flex flexItemCenter saLogo px-15 pxD-35 ">
                    <div class="logoRotate2 mr-5">
                        <img src="../../../../assets/logo_icon.svg">
                    </div>
                    <div class="logoText">
                        <img src="../../../../assets/sa_text2.svg">
                    </div>
                </div>
                <div id="burgerMenu" class="flexRight px-15 pxD-35 ">
                    <div class="burger" (click)="openMenu()">
                        <div class="line1"></div>
                        <div class="short line2"></div>
                        <div class="line3"></div>
                    </div>
                </div>
            </div>
            <div class="gridEnd desktopHeaderLineBox ">
                <div class="px-15 desktopPx-35">
                    <small class="hero">
                        Smarter<span>POD</span>
                    </small>
                    <h3 class="mt-10 mb-20"><span>R</span>EAL-TIME <span>M</span>ANAGED<br> <span>D</span>ELIVERIES
                    </h3>
                    <section class="moreArrow" (click)="fullpage_api.moveTo('pod', 1);">
                        We support the drivers and operations control teams in efficient delivery execution. ​
                        <span class="arrowButton"><img src="../../../../assets/arrowN.svg"></span>
                    </section>
                </div>
            </div>
        </div>
        <!-- Szablon POD -->
        <div class="slide slidePanel">
            <!-- Nagłówek & menu -->
            <section class="gridWraperLayout desctopContent mb-10 px-15">
                <div class="logo2">
                    <img src="assets/logo_ba.svg">
                </div>
                <div class="burger2" (click)="fullpage_api.moveTo('pod', 0);">
                    <div class="line1"></div>
                    <div class="short line2"></div>
                    <div class="line3"></div>
                </div>
            </section>
            <section class="sectionImage mb-20" id="podTopImg2"></section>
            <section class="desctopContent px-15 desctop40">
                <!-- Wstęp -->
                <section class="headerLine mb-20">
                    <div class="iconImg"></div>
                    <h3>SmarterPOD</h3>
                </section>
                <article class="mb-20 mtbD-30">
                    <b>SmarterPOD</b> is a Proof of Delivery class software that enables paper less, digital only
                    delivery process management. The system supports all delivery process creators, the driver,
                    operations control teams, the clients and collectors as well as the logistics business
                    administration. ​
                    <br><br>
                    SmarterPOD mobile app support for the driver​
                </article>
                <!-- Rozwiązania dla dużych i małych firm -->
                <section class="desctopContent sectionImage " id="podMiddImg"></section>
                <ul class="logicList mb-20 mtbD-30">
                    <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon18.svg"></div>
                        </div>
                        <div class="text">
                            <b>Digital Delivery Plan for the Driver </b>
                            Mobile application either available for IOS/Android smartphone or industrial mobile devices.
                        </div>
                    </li>
                    <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon2.svg"></div>
                        </div>
                        <div class="text"><b>Loading</b>
                            Shorter and errorless loading. App led process helps the driver to verify the shipment
                            during the loading.
                        </div>
                    </li>
                    <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon3.svg"></div>
                        </div>
                        <div class="text">
                            <b>Route to the Collectors</b>
                            App navigation optimize the route and proactively warns the driver about the potential
                            delays. The system also automatically notify the collectors about the delivery time slot.
                        </div>
                    </li>
                    <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon4.svg"></div>
                        </div>
                        <div class="text">
                            <b>Delivery</b>
                            Digital sign on glass. Online completed delivery status triggering automated invoicing. App
                            led extra services provision. Online payment or cash on delivery supported.
                        </div>
                    </li>
                    <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon5.svg"></div>
                        </div>
                        <div class="text">
                            <b>Shipment Collection from the Collectors</b>
                            Returns and new shipment collection process supported by the app.
                        </div>
                    </li>
                    <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon7.svg"></div>
                        </div>
                        <div class="text">
                            <b>Unloading at the Base</b>
                            App supported unloading process of collected shipments, returns, reusable packaging, etc.
                        </div>
                    </li>
                    <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon37.svg"></div>
                        </div>
                        <div class="text">
                            <b>Route Settlement</b>
                            Delivery service status and balance done automatically by the system including delivered and
                            collected items, the distance, fuel costs, accepted payments, etc.
                        </div>
                    </li>
                </ul>
                <!-- Kluczowe procesy dostawy -->
                <section>
                    <div class="postArea mb-20 mtbD-30">
                        <div class="postButtons">
                            <div class="postButtonsItem">
                                <div>Operations Control Team Support via Web Application</div>
                            </div>
                            <div class="descItem mt-20">
                                <ul class="deliveryProcessList">
                                    <li class="mb-10">
                                        Real-time delivery status. Task details instant view e.g. driver performance,
                                        location, potential delays, ETA, etc.
                                    </li>
                                    <li class="mb-10">
                                        Automatic notifications, alerts preventing potential delays.
                                    </li>
                                    <li class="mb-10">
                                        Route real-time management, online route and delivery status review
                                    </li>
                                    <li class="mb-10">
                                        Real-time proof of delivery. Sign on glass, photo acceptance. Extra services
                                        provision status. Payment status. Data feed for automated invoicing.
                                    </li>
                                    <li class="mb-10">
                                        Photos of delivered shipment, documents, complaints acceptance and management.
                                        Automatically recommended actions for the driver.
                                    </li>
                                    <li class="mb-10">
                                        Dynamic reporting. Multi level data filters.
                                    </li>
                                    <li class="mb-10">
                                        Driver base management. New drivers adding, authorization level assignment, etc.
                                    </li>
                                    <li class="mb-10">
                                        Collector base management. Collection points special requirements e.g. ramp
                                        approach route and conditions.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="postButtons">
                            <div class="postButtonsItem">
                                <div>Back Office</div>
                            </div>
                            <div class="descItem mt-20">
                                <ul class="deliveryProcessList">
                                    <li class="mb-10">
                                        Digital shipment documents management
                                    </li>
                                    <li class="mb-10">
                                        Photo proof of task completion, broken items, etc. 
                                    </li>
                                    <li class="mb-10">
                                        Reusable packaging management (pallets, etc.)
                                    </li>
                                    <li class="mb-10">
                                        Complaints management module for internal communication. 
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="postButtons">
                            <div class="postButtonsItem">
                                <div>Customer Care Team Support via Web Application</div>
                            </div>
                            <div class="descItem mt-20">
                                <ul class="deliveryProcessList">
                                    <li class="mb-10">
                                        Automatic notification of potential delays and disruptions. 
                                    </li>
                                    <li class="mb-10">
                                        Real-time shipment status and its parameters (e.g. temperature). 
                                    </li>
                                    <li class="mb-10">
                                        Real-time complaints management based on photo feed and online communication
                                        with the driver and collector.
                                    </li>
                                    <li class="mb-10">
                                        Real-time proof of delivery. Sign on glass, photo acceptance. Extra services
                                        provision status. Payment status.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="postButtons">
                            <div class="postButtonsItem">
                                <div>Business Management</div>
                            </div>
                            <div class="descItem mt-20">
                                <ul class="deliveryProcessList">
                                    <li class="mb-10">
                                        Instant reporting of service progress and status. 
                                    </li>
                                    <li class="mb-10">
                                        Customized reports e.g. delays, complaints.
                                    </li>
                                    <li class="mb-10">
                                        KPI reporting, ad-hoc, daily, weekly, etc. 
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <article class="mb-20 mtbD-30">
                        <b>SmarterPOD</b> is designed to support mobile operations of logistics operators and
                        manufacturers or service providers managing logistics operations internally. ​
                    </article>
                    <div class="mb-20 mtbD-30">
                        <ul class="logicList2">
                            <li class="gridLine">
                                <div class="iconBox2">
                                    <div class="icon"><img src="assets/icon9.svg"></div>
                                </div>
                                <div class="text">
                                    <b>Logistics Operators</b>
                                    SmarterPOD is currently used by many big logistics operators across Europe. We
                                    support our clients in Poland, France, Czech Republic or Italy among others.
                                </div>
                            </li>
                            <li class="gridLine">
                                <div class="iconBox2">
                                    <div class="icon"><img src="assets/icon10.svg"></div>
                                </div>
                                <div class="text">
                                    <b>Logistics Self-handlers.</b>
                                    SmarterPOD is currently used by companies that require highly specialized transport
                                    and logistics services, e.g. med labs or industrial components manufacturers.
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
            </section>
            <!-- Stopka -->
            <section class="footertop px-15">
                <div class="desctopContent">
                    <div class="mb-20 mt-10">
                        <b style="font-size: 18px;">Get in touch with us.</b>
                        <br><br>
                        Find out how we can improve the efficiency of your business. ​
                    </div>
                    <div class="mb-20">
                        <button class="contactBtn accentBg" (click)="contactUs()">
                            Contact Us​
                        </button>
                    </div>
                </div>
            </section>
            <!-- podziel się -->
            <section class="footerMiddle pt-10 px-15">
                <div class="desctopContent">
                    <div class="flex flexItemCenter mb-10">
                        <div class="icon"><img src="assets/m1.svg"></div>
                        <div class="ml-20">Download PDF</div>
                    </div>
                    <div class="flex flexItemCenter mb-10">
                        <div class="icon"><img src="assets/m2.svg"></div>
                        <div class="ml-20">Share</div>
                    </div>
                    <div class="flex flexItemCenter backButton">
                        <div class="icon"><img src="assets/m3.svg"></div>
                        <div class="ml-20" (click)="fullpage_api.moveTo('pod', 0);">Back</div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <!-- YMS -->
    <div class="section coverImg" id="screen3">
        <div class="slide mainScreen">
            <div class="topWrapper">
                <div id="logoMenu" class="flex flexItemCenter saLogo px-15 pxD-35 ">
                    <div class="logoRotate2 mr-5">
                        <img src="../../../../assets/logo_icon.svg">
                    </div>
                    <div class="logoText">
                        <img src="../../../../assets/sa_text2.svg">
                    </div>
                </div>
                <div id="burgerMenu" class="flexRight px-15 pxD-35 ">
                    <div class="burger" (click)="openMenu()">
                        <div class="line1"></div>
                        <div class="short line2"></div>
                        <div class="line3"></div>
                    </div>
                </div>
            </div>
            <div class="gridEnd desktopHeaderLineBox ">
                <div class="px-15 desktopPx-35">
                    <small class="hero black white">Smarter<span>YMS</span></small>
                    <!-- <h3 class="mt-10 mb-20"><span>D</span>igitalizujemy<br> i <span>a</span>utomatyzujemy operacje <br> w
                        <span>m</span>agazynach i centrach<br> <span>l</span>ogistycznych</h3> -->
                    <h3 class="mt-10 mb-20">
                        <span>D</span>IGITALLY <span>M</span>ANAGED <br> <span>Y</span>ARD <span>O</span>PERATIONS
                    </h3>
                    <section class="moreArrow" (click)="fullpage_api.moveTo('yms', 1);">
                        Automatic Gate, Digital Notifications Management, On-yard Vehicle Management, Ramp Work Capacity
                        Management. ​
                        <span class="arrowButton"><img src="../../../../assets/arrowN.svg"></span>
                    </section>
                </div>
                <div class="shortStory halfGrid">
                    <div class="halfGrid">
                        <div class="iconTextBlock">
                            <div class="icon">
                                <img src="../../../../assets/awi.svg">
                            </div>
                            <div class="content">
                                <div class="header">
                                    Digital Notifications
                                </div>
                                <div class="text">
                                    Real-time notifications management, web booking based 3rd party notifications
                                    creation and management.
                                </div>
                            </div>
                        </div>
                        <div class="iconTextBlock">
                            <div class="icon">
                                <img src="../../../../assets/ope.svg">
                            </div>
                            <div class="content">
                                <div class="header">
                                    On-Yard Operations Management​
                                </div>
                                <div class="text">
                                    Vehicle and drivers on-yard management e.g. parking, pause, ramp approach.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="halfGrid">
                        <div class="iconTextBlock">
                            <div class="icon">
                                <img src="../../../../assets/zas.svg">
                            </div>
                            <div class="content">
                                <div class="header">
                                    Workforce Capacity Management
                                </div>
                                <div class="text">
                                    Real-time work resources management, e.g. ramp, support or security staff.
                                </div>
                            </div>
                        </div>
                        <div class="iconTextBlock">
                            <div class="icon">
                                <img src="../../../../assets/aut.svg">
                            </div>
                            <div class="content">
                                <div class="header">
                                    Automation
                                </div>
                                <div class="text">
                                    Gate operations automation e.g. driver, vehicle, shipment verification, notification
                                    authorization, task assignment.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Szablon YMS -->
        <div class="slide slidePanel">
            <!-- Nagłówek & menu -->
            <section class="gridWraperLayout desctopContent mb-10 px-15">
                <div class="logo2">
                    <img src="assets/logo_ba.svg">
                </div>
                <div class="burger2" (click)="fullpage_api.moveTo('yms', 0);">
                    <div class="line1"></div>
                    <div class="short line2"></div>
                    <div class="line3"></div>
                </div>
            </section>
            <section class="sectionImage mb-20" id="podTopImg3"></section>
            <section class="desctopContent px-15">
                <!-- Wstęp -->
                <section class="headerLine mb-20">
                    <div class="iconImg"></div>
                    <h3>SmarterYMS</h3>
                </section>
                <section>
                    <article class="mb-20 mtbD-30">
                        <b>SmarterYMS</b> is a Yard Management class software that enables paperless and automated
                        management of yard operations e.g. notifications, gate and ramp operations, vehicle movement,
                        parking management. The system supports instant communication of allocated task to all of
                        process creators, i.e. the drivers, gate clerks, security, ramp and support staff as well as
                        operations control teams and logistics business administration. <br><br>
                        SmarterYMS support for the driver.
                    </article>
                </section>
                <!-- YMS cd -->
                <!-- <section class="sectionImage mb-20" id="podMiddImgb"></section> -->
                <ul class="logicList mb-20 mtbD-30">
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon19.svg"></div>
                        </div>
                        <div class="text">
                            <b>WEBbooking</b>
                            Yard visits notification created by 3rd parties e.g. manufacturers, collectors – logistics
                            operator clients.
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon20.svg"></div>
                        </div>
                        <div class="text"><b>Yard Visit Notification Planning</b>
                            Manual or integration based (WMS/TMS) yard visits notifications.
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon22.svg"></div>
                        </div>
                        <div class="text">
                            <b>Automatic Gate</b>
                            Gate operations automation e.g. driver, vehicle, shipment verification, notification
                            authorization, task assignment.
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon21.svg"></div>
                        </div>
                        <div class="text">
                            <b>Vehicle Movement Management </b>
                            Ramp service sequence, parking operations management
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon5.svg"></div>
                        </div>
                        <div class="text">
                            <b>Loading / Unloading Planning​</b>
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon7.svg"></div>
                        </div>
                        <div class="text">
                            <b>Multi-loading / Multi Unloading Operations Support​</b>
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon23.svg"></div>
                        </div>
                        <div class="text">
                            <b>Automatic Disruption Notifications</b>
                            System or human sourced notification causing disruptions delivered automatically to the
                            process parties.
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon25.svg"></div>
                        </div>
                        <div class="text">
                            <b>Automatic Alerts</b>
                            System or human sourced notification preventing potential disruptions delivered
                            automatically to the process parties.
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon8.svg"></div>
                        </div>
                        <div class="text">
                            <b>Vehicle Monitoring</b>
                            Automatic location of the vehicles and drivers on the yard, e.g. parking, ramp, service, car
                            wash,
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon26.svg"></div>
                        </div>
                        <div class="text">
                            <b>Service Slots Management​</b>
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon27.svg"></div>
                        </div>
                        <div class="text">
                            <b>Management Rules per Client</b>
                            Service rules and priorities defined by each client type or product type, etc. individually
                            defined.
                        </div>
                    </li>
                </ul>

                <section>
                    <!-- Kluczowe procesy dostawy -->
                    <div class="postArea mb-20 mtbD-30">
                        <div class="postButtons">
                            <div class="postButtonsItem">
                                <div>Gate and Security</div>
                            </div>
                            <div class="descItem mt-20">
                                <ul class="deliveryProcessList">
                                    <li class="mb-10">
                                        Automatic visit notifications
                                    </li>
                                    <li class="mb-10">
                                        Real-time performance monitoring of gates, totems, infokiosks (SmarterGATE)
                                    </li>
                                    <li class="mb-10">
                                        Active visit status, i.e. currently served, planned, delayed, completed.
                                    </li>
                                    <li class="mb-10">
                                        Automatics, RFID and MIFARE base shipment verification.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="postButtons">
                            <div class="postButtonsItem">
                                <div>Warehouse</div>
                            </div>
                            <div class="descItem mt-20">
                                <ul class="deliveryProcessList">
                                    <li class="mb-10">
                                        Warehouse man force capacity planning
                                    </li>
                                    <li class="mb-10">
                                        Real-time notifications management, clear status visualization.
                                    </li>
                                    <li class="mb-10">
                                        Real-time notifications management, clear status visualization.
                                    </li>
                                    <li class="mb-10">
                                        Automatic communication with the drivers. 
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="postButtons">
                            <div class="postButtonsItem">
                                <div>Transport</div>
                            </div>
                            <div class="descItem mt-20">
                                <ul class="deliveryProcessList">
                                    <li class="mb-10">
                                        Automatic visit notifications, limits control, integration with WMS, ERP.
                                    </li>
                                    <li class="mb-10">
                                        Real-time notification status, disruptions, delays, etc. 
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="postButtons">
                            <div class="postButtonsItem">
                                <div>Drivers</div>
                            </div>
                            <div class="descItem mt-20">
                                <ul class="deliveryProcessList">
                                    <li class="mb-10">
                                        SmarterGATE automatic gate operation e.g. driver, shipment, documents
                                        identification, verification, etc.
                                    </li>
                                    <li class="mb-10">
                                        Automatic service sequence planning.
                                    </li>
                                    <li class="mb-10">
                                        Dedicated communication and alerts to for the individual drivers.
                                    </li>
                                    <li class="mb-10">
                                        Remote visit registration (WebBooking)​
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="postButtons">
                            <div class="postButtonsItem">
                                <div>Reports</div>
                            </div>
                            <div class="descItem mt-20">
                                <ul class="deliveryProcessList">
                                    <li class="mb-10">
                                        Personalized and ad-hoc reports
                                    </li>
                                    <li class="mb-10">
                                        KPI and SLA definition
                                    </li>
                                    <li class="mb-10">
                                        Dashboards
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <article class="mb-20 mtbD-30">
                        <b>SmarterYMS</b> to rozwiązanie dla firm logistycznych, firm produkcyjnych
                        i dystrybutorów.
                    </article>
                    <div class="mb-20 mtbD-30">
                        <ul class="logicList">
                            <li class="gridLine">
                                <div class="iconBox2">
                                    <div class="icon"><img src="assets/icon9.svg"></div>
                                </div>
                                <div class="text">
                                    <b>Logistics Operators</b>
                                    Currently we serve dozens of logistics platforms in Europe. We support our clients
                                    in Poland, France, Czech Republic or Italy among others.
                                </div>
                            </li>
                            <li class="gridLine">
                                <div class="iconBox2">
                                    <div class="icon"><img src="assets/icon10.svg"></div>
                                </div>
                                <div class="text">
                                    <b>Logistics Self-handlers </b>
                                    SmarterYMS is currently used by manufacturers handling the logistics internally in
                                    multiple locations in CEE.
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
            </section>
            <!-- Stopka -->
            <section class="footertop px-15">
                <div class="desctopContent">
                    <div class="mb-20 mt-10">
                        <b style="font-size: 18px;">Get in touch with us.</b>
                        <br><br>
                        Find out how we can improve the efficiency of your business. ​
                    </div>
                    <div class="mb-20">
                        <button class="contactBtn accentBg" (click)="contactUs()">
                            Contact Us​
                        </button>
                    </div>
                </div>
            </section>
            <!-- podziel się -->
            <section class="footerMiddle pt-10 px-15">
                <div class="desctopContent">
                    <div class="flex flexItemCenter mb-10">
                        <div class="icon"><img src="assets/m1.svg"></div>
                        <div class="ml-20">Download PDF</div>
                    </div>
                    <div class="flex flexItemCenter mb-10">
                        <div class="icon"><img src="assets/m2.svg"></div>
                        <div class="ml-20">Share</div>
                    </div>
                    <div class="flex flexItemCenter backButton">
                        <div class="icon"><img src="assets/m3.svg"></div>
                        <div class="ml-20" (click)="fullpage_api.moveTo('yms', 0);">Back</div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <!-- Operatorzy logistyczni -->
    <div class="section coverImg" id="screen4">
        <div class="slide">
            <div class="topWrapper">
                <div id="logoMenu" class="flex flexItemCenter saLogo px-15 pxD-35 ">
                    <div class="logoRotate2 mr-5">
                        <img src="../../../../assets/logo_icon.svg">
                    </div>
                    <div class="logoText">
                        <img src="../../../../assets/sa_text2.svg">
                    </div>
                </div>
                <div id="burgerMenu" class="flexRight px-15 pxD-35 ">
                    <div class="burger" (click)="openMenu()">
                        <div class="line1"></div>
                        <div class="short line2"></div>
                        <div class="line3"></div>
                    </div>
                </div>
            </div>
            <div class="gridEnd desktopHeaderLineBox ">
                <div class="px-15 desktopPx-35">
                    <small class="hero">Logistics Operators</small>
                    <h3 class="mt-10 mb-20"><span>W</span>E <span>I</span>NTEGRATE<br> <span>S</span>UPPLY
                        <span>C</span>HAINS
                    </h3>
                    <section class="moreArrow" (click)="fullpage_api.moveTo('logistics-operators', 1);">
                        We enable real-time delivery management and yard operations management by integrating with TMS,
                        WMS and ERP systems. ​
                        <span class="arrowButton"><img src="../../../../assets/arrowN.svg"></span>
                    </section>
                </div>
            </div>
        </div>
        <!-- Szablon Operatorzy logistyczni -->
        <div class="slide slidePanel">
            <!-- Nagłówek & menu -->
            <section class="gridWraperLayout desctopContent mb-10 px-15">
                <div class="logo2">
                    <img src="assets/logo_ba.svg">
                </div>
                <div class="burger2" (click)="fullpage_api.moveTo('operatorzy-logistyczni', 0);">
                    <div class="line1"></div>
                    <div class="short line2"></div>
                    <div class="line3"></div>
                </div>
            </section>
            <section class="sectionImage mb-20" id="podTopImg4"></section>
            <section class="desctopContent px-15">

                <!-- Wstęp -->
                <section class="headerLine mb-20">
                    <div class="iconImg"></div>
                    <h3>Logistics Operators​</h3>
                </section>
                <section>
                    <article class="mb-20 mtbD-30">
                        We deliver solutions enabling mobile logistics management real-time. SmarterPOD and SmarterYMS
                        capture operating data during the delivery and yard operations, in the place and time of its
                        creation. We eliminate paper based, analog management. We standardize and digitize processes. We
                        supply core Supply Chain Execution with complete, good quality and real-time data,
                        simultaneously integrating the WMS and TMS systems used by our clients. Our system deliver
                        better business performance in the field of: ​
                    </article>
                </section>
                <div class="mb-20 mtbD-30">
                    <ul class="logicList">
                        <li class="gridLine">
                            <div class="iconBox2">
                                <div class="icon"><img src="assets/icon28.svg"></div>
                            </div>
                            <div class="text">
                                Shorter time-to-money​
                            </div>
                        </li>
                        <li class="gridLine">
                            <div class="iconBox2">
                                <div class="icon"><img src="assets/icon29.svg"></div>
                            </div>
                            <div class="text">
                                Better delivery time​
                            </div>
                        </li>
                        <li class="gridLine">
                            <div class="iconBox2">
                                <div class="icon"><img src="assets/icon30.svg"></div>
                            </div>
                            <div class="text">
                                Better resource productivity​
                            </div>
                        </li>
                        <li class="gridLine">
                            <div class="iconBox2">
                                <div class="icon"><img src="assets/icon31.svg"></div>
                            </div>
                            <div class="text">
                                Lover operating unit cost​
                            </div>
                        </li>
                        <li class="gridLine">
                            <div class="iconBox2">
                                <div class="icon"><img src="assets/icon32.svg"></div>
                            </div>
                            <div class="text">
                                Operation Transparency​
                            </div>
                        </li>
                    </ul>
                </div>
                <!-- Rozwiązania dla dużych i małych firm -->
                <article class="mb-20 mtbD-30">
                    <b>Sectors​:</b>
                </article>
                <!-- Wspierane branże -->
                <section class="gridBox3Columns mb-20 mtbD-30">
                    <div class="flip-card" [ngClass]="{'showBack' : showBack}" (click)="showBack = !showBack">
                        <div class="flip-card-inner">
                            <div class="flip-card-front">
                                <div class="borderBox">
                                    <div class="icon mb-5"><img src="assets/icon001.svg"></div>
                                    <div class="text">
                                        Fresh
                                    </div>
                                </div>
                            </div>
                            <div class="flip-card-back">
                                <div class="borderBox">
                                    Real-time cargo parameters monitoring e.g. temperature, humidity. Real-tome
                                    complaints management. Reusable packaging management. Mobile payments.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flip-card" [ngClass]="{'showBack' : showBack2}" (click)="showBack2 = !showBack2">
                        <div class="flip-card-inner">
                            <div class="flip-card-front">
                                <div class="borderBox">
                                    <div class="icon mb-5"><img src="assets/icon002.svg"></div>
                                    <div class="text">
                                        Food & Beverages
                                    </div>
                                </div>
                            </div>
                            <div class="flip-card-back">
                                <div class="borderBox">
                                    Real-time cargo parameters monitoring e.g. temperature, humidity. Real-tome
                                    complaints management. Reusable packaging management. Mobile payments.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flip-card" [ngClass]="{'showBack' : showBack3}" (click)="showBack3 = !showBack3">
                        <div class="flip-card-inner">
                            <div class="flip-card-front">
                                <div class="borderBox">
                                    <div class="icon mb-5"><img src="assets/icon003.svg"></div>
                                    <div class="text">
                                        FMCG
                                    </div>
                                </div>
                            </div>
                            <div class="flip-card-back">
                                <div class="borderBox">
                                    Real-time cargo status monitoring. Real-tome complaints management. Reusable
                                    packaging management. Mobile payments. Extra services support.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flip-card" [ngClass]="{'showBack' : showBack4}" (click)="showBack4 = !showBack4">
                        <div class="flip-card-inner">
                            <div class="flip-card-front">
                                <div class="borderBox">
                                    <div class="icon mb-5"><img src="assets/icon004.svg"></div>
                                    <div class="text">
                                        Pharmaceutics & Med-Labs
                                    </div>
                                </div>
                            </div>
                            <div class="flip-card-back">
                                <div class="borderBox">
                                    Real-time cargo parameters monitoring e.g. temperature, humidity. Real-tome
                                    complaints management. Reusable packaging management. Mobile payments.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flip-card" [ngClass]="{'showBack' : showBack5}" (click)="showBack5 = !showBack5">
                        <div class="flip-card-inner">
                            <div class="flip-card-front">
                                <div class="borderBox">
                                    <div class="icon mb-5"><img src="assets/icon008.svg"></div>
                                    <div class="text">
                                        Building Materials
                                    </div>
                                </div>
                            </div>
                            <div class="flip-card-back">
                                <div class="borderBox">
                                    Real-time cargo status monitoring. Real-tome complaints management. Reusable
                                    packaging management. Mobile payments. Extra services support.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flip-card" [ngClass]="{'showBack' : showBack6}" (click)="showBack6= !showBack6">
                        <div class="flip-card-inner">
                            <div class="flip-card-front">
                                <div class="borderBox">
                                    <div class="icon mb-5"><img src="assets/icon006.svg"></div>
                                    <div class="text">
                                        Electronics, Furnishing, Home Appliance.
                                    </div>
                                </div>
                            </div>
                            <div class="flip-card-back">
                                <div class="borderBox">
                                    Real-time cargo status monitoring. Real-tome complaints management. Reusable
                                    packaging management. Mobile payments. Extra services support.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flip-card" [ngClass]="{'showBack' : showBack7}" (click)="showBack7 = !showBack7">
                        <div class="flip-card-inner">
                            <div class="flip-card-front">
                                <div class="borderBox">
                                    <div class="icon mb-5"><img src="assets/icon007.svg"></div>
                                    <div class="text">
                                        B2B Distribution, Automotive
                                    </div>
                                </div>
                            </div>
                            <div class="flip-card-back">
                                <div class="borderBox">
                                    Real-time cargo status monitoring. Real-tome complaints management. Reusable
                                    packaging management. Mobile payments. Extra services support.
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            <!-- Stopka -->
            <section class="footertop px-15">
                <div class="desctopContent">
                    <div class="mb-20 mt-10">
                        <b style="font-size: 18px;">Get in touch with us.</b>
                        <br><br>
                        Find out how we can improve the efficiency of your business. ​
                    </div>
                    <div class="mb-20">
                        <button class="contactBtn accentBg" (click)="contactUs()">
                            Contact Us​
                        </button>
                    </div>
                </div>
            </section>
            <!-- podziel się -->
            <section class="footerMiddle pt-10 px-15">
                <div class="desctopContent">
                    <div class="flex flexItemCenter mb-10">
                        <div class="icon"><img src="assets/m1.svg"></div>
                        <div class="ml-20">Download PDF</div>
                    </div>
                    <div class="flex flexItemCenter mb-10">
                        <div class="icon"><img src="assets/m2.svg"></div>
                        <div class="ml-20">Share</div>
                    </div>
                    <div class="flex flexItemCenter backButton">
                        <div class="icon"><img src="assets/m3.svg"></div>
                        <div class="ml-20" (click)="fullpage_api.moveTo('logistics-operators', 0);">Back</div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <!-- Producenci i dystrybutorzy -->
    <div class="section coverImg" id="screen5">
        <div class="slide">
            <div class="topWrapper">
                <div id="logoMenu" class="flex flexItemCenter saLogo px-15 pxD-35 ">
                    <div class="logoRotate2 mr-5">
                        <img src="../../../../assets/logo_icon.svg">
                    </div>
                    <div class="logoText">
                        <img src="../../../../assets/sa_text2.svg">
                    </div>
                </div>
                <div id="burgerMenu" class="flexRight px-15 pxD-35 ">
                    <div class="burger" (click)="openMenu()">
                        <div class="line1"></div>
                        <div class="short line2"></div>
                        <div class="line3"></div>
                    </div>
                </div>
            </div>
            <div class="gridEnd desktopHeaderLineBox ">
                <div class="px-15 desktopPx-35">
                    <small class="hero">Distributors & Manufacturers</small>
                    <h3 class="mt-10 mb-20">
                        <span>TSL</span> <span> D</span>IGITIZATION <br>
                        <span>B</span>EST <span>P</span>RACTICES <br>
                        <span>A</span>VAILABLE TO <span>L</span>OGISTICS<br>
                        <span>S</span>ELF-HANDLERS​
                    </h3>
                    <section class="moreArrow" (click)="fullpage_api.moveTo('distributors-and-manufacturers', 1);">
                        We deliver Transport and Logistics digitization best practices to manufacturers and distributors
                        handling logistics internally.​
                        <span class="arrowButton"><img src="../../../../assets/arrowN.svg"></span>
                    </section>
                </div>
                <div class="shortStory halfGrid">
                    <div class="textBlock">
                        <div class="icon">
                            <img src="../../../../assets/icon45.svg">
                        </div>
                        <div class="textContent">
                            <div class="header">
                                Digital Transformation in Mobile Logistics Management
                            </div>
                            <div class="content">
                                We lead logistics self-handlers through digital transformation with TSL market best
                                practices in the field of process definition, standardization and optimized management.
                            </div>
                        </div>
                    </div>
                    <div class="textBlock">
                        <div class="icon">
                            <img src="../../../../assets/icon46.svg">
                        </div>
                        <div class="textContent">
                            <div class="header">
                                Operations Transparency and Optimization
                            </div>
                            <div class="content">
                                We increase efficiency of transport and yard operations and decrease delivery unit
                                costs. We deliver process transparency, real-time visualization of delivery, ramp, gate,
                                ancillary service operations. We proactively prevent operation disruptions.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Szablon Producenci i dystrybutorzy -->
        <div class="slide slidePanel">
            <!-- Nagłówek & menu -->
            <section class="gridWraperLayout desctopContent px-15 mb-10">
                <div class="logo2">
                    <img src="assets/logo_ba.svg">
                </div>
                <div class="burger2" (click)="fullpage_api.moveTo('distributors-and-manufacturers', 0);">
                    <div class="line1"></div>
                    <div class="short line2"></div>
                    <div class="line3"></div>
                </div>
            </section>
            <section class="sectionImage mb-20" id="podTopImg5"></section>
            <section class="desctopContent px-15">
                <!-- Wstęp -->
                <section class="headerLine mb-20">
                    <div class="iconImg"></div>
                    <h3>Distributors & Manufacturers​</h3>
                </section>
                <section>
                    <article class="mb-20 mtbD-30">
                        <b>SmarterApps</b> supports manufacturers and distributors in mobile logistics management.
                    </article>
                </section>
                <!-- Rozwiązania dla dużych i małych firm -->
                <ul class="logicList mb-20 mtbD-30">
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon33.svg"></div>
                        </div>
                        <div class="text">
                            Process standardization, optimization and automation​
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon34.svg"></div>
                        </div>
                        <div class="text">
                            Optimized delivery planning​
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon35.svg"></div>
                        </div>
                        <div class="text">
                            Drivers led by SmarterPOD mobile apps through optimized and automated delivery process. ​
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon26.svg"></div>
                        </div>
                        <div class="text">
                            Yard staff supported. SmarterGATE automates gate operations, SmarterYMS optimizes loading
                            and other yard operations. ​
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon19.svg"></div>
                        </div>
                        <div class="text">
                            Operation control team empowered with real-time delivery and yard operations management
                            tools. ​
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon25.svg"></div>
                        </div>
                        <div class="text">
                            Proactive prevention of operation disruptions.​
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon16.svg"></div>
                        </div>
                        <div class="text">
                            Ad hoc reporting and easy to define, individual reporting including KPIs, disruptions and
                            their causes, online delivery status, etc. ​
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon17.svg"></div>
                        </div>
                        <div class="text">
                            Online delivery status information for clients, and collectors.​
                        </div>
                    </li>
                </ul>
                <article class="mb-20 mtbD-30 heroText">
                    <b>We increase quality and efficiency of logistics operations.</b>
                </article>
            </section>
            <!-- Stopka -->
            <section class="footertop px-15">
                <div class="desctopContent">
                    <div class="mb-20 mt-10">
                        <b style="font-size: 18px;">Get in touch with us.</b>
                        <br><br>
                        Find out how we can improve the efficiency of your business. ​
                    </div>
                    <div class="mb-20">
                        <button class="contactBtn accentBg" (click)="contactUs()">
                            Contact Us​
                        </button>
                    </div>
                </div>
            </section>
            <!-- podziel się -->
            <section class="footerMiddle pt-10 px-15">
                <div class="desctopContent">
                    <div class="flex flexItemCenter mb-10">
                        <div class="icon"><img src="assets/m1.svg"></div>
                        <div class="ml-20">Download PDF</div>
                    </div>
                    <div class="flex flexItemCenter mb-10">
                        <div class="icon"><img src="assets/m2.svg"></div>
                        <div class="ml-20">Share</div>
                    </div>
                    <div class="flex flexItemCenter backButton">
                        <div class="icon"><img src="assets/m3.svg"></div>
                        <div class="ml-20" (click)="fullpage_api.moveTo('distributors-and-manufacturers', 0);">Back
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <!-- Firmy Transportowe -->
    <div class="section coverImg" id="screen6">
        <div class="slide">
            <div class="topWrapper">
                <div id="logoMenu" class="flex flexItemCenter saLogo px-15 pxD-35 ">
                    <div class="logoRotate2 mr-5">
                        <img src="../../../../assets/logo_icon.svg">
                    </div>
                    <div class="logoText">
                        <img src="../../../../assets/sa_text2.svg">
                    </div>
                </div>
                <div id="burgerMenu" class="flexRight px-15 pxD-35 ">
                    <div class="burger" (click)="openMenu()">
                        <div class="line1"></div>
                        <div class="short line2"></div>
                        <div class="line3"></div>
                    </div>
                </div>
            </div>
            <div class="gridEnd desktopHeaderLineBox ">
                <div class="px-15 desktopPx-35">
                    <small class="hero">Transport Companies</small>
                    <h3 class="mt-10 mb-20">
                        <span>F</span>ULL <span>C</span>ONTROL OF<br>
                        <span>D</span>ELIVERY <span>O</span>PERATIONS
                    </h3>
                    <section class="moreArrow" (click)="fullpage_api.moveTo('transport-companies', 1);">
                        Drivers, tasks and costs under real-time control. Digitally managed delivery operations.
                        Proactive prevention of delays and disruptions. ​
                        <span class="arrowButton"><img src="../../../../assets/arrowN.svg"></span>
                    </section>
                </div>
            </div>
        </div>
        <!-- Szablon Firmy Transportowe -->
        <div class="slide slidePanel">
            <!-- Nagłówek & menu -->
            <section class="gridWraperLayout desctopContent mb-10 px-15">
                <div class="logo2">
                    <img src="assets/logo_ba.svg">
                </div>
                <div class="burger2" (click)="fullpage_api.moveTo('transport-companies', 0);">
                    <div class="line1"></div>
                    <div class="short line2"></div>
                    <div class="line3"></div>
                </div>
            </section>
            <section class="sectionImage mb-20" id="podTopImg6"></section>
            <section class="desctopContent px-15">
                <!-- Wstęp -->
                <section class="headerLine mb-20">
                    <div class="iconImg"></div>
                    <h3>Transport Companies ​</h3>
                </section>
                <section>
                    <article class="mb-20 mtbD-30">
                        <b>SmarterPOD<span class="redColor italic">lite</span></b>supports owners and managers of
                        transport companies in efficient and real-time controlled delivery management.
                    </article>
                    <ul class="logicList mb-20 mtbD-30">
                        <li class="gridLine">
                            <div class="iconBox">
                                <div class="icon"><img src="assets/icon36.svg"></div>
                            </div>
                            <div class="text">
                                Route planning with “drag and drop” functionality​
                            </div>
                        </li>
                        <li class="gridLine">
                            <div class="iconBox">
                                <div class="icon"><img src="assets/icon38.svg"></div>
                            </div>
                            <div class="text">
                                Telematics integration, driver monitoring e.g. tachograph, eco driving.​
                            </div>
                        </li>
                        <li class="gridLine">
                            <div class="iconBox">
                                <div class="icon"><img src="assets/icon39.svg"></div>
                            </div>
                            <div class="text">
                                Route and task optimization improving the quality of delivery service. ​
                            </div>
                        </li>
                        <li class="gridLine">
                            <div class="iconBox">
                                <div class="icon"><img src="assets/icon40.svg"></div>
                            </div>
                            <div class="text">
                                Real-time vehicle location and driver support in navigating to the delivery points. ​
                            </div>
                        </li>
                        <li class="gridLine">
                            <div class="iconBox">
                                <div class="icon"><img src="assets/icon25.svg"></div>
                            </div>
                            <div class="text">
                                Automatic Disruption Notifications. System or human sourced notification causing
                                disruptions delivered automatically to the process parties. ​
                            </div>
                        </li>
                        <li class="gridLine">
                            <div class="iconBox">
                                <div class="icon"><img src="assets/icon41.svg"></div>
                            </div>
                            <div class="text">
                                System or human sourced notification preventing potential disruptions and complaints
                                delivered automatically to the process parties. ​
                            </div>
                        </li>
                        <li class="gridLine">
                            <div class="iconBox">
                                <div class="icon"><img src="assets/icon42.svg"></div>
                            </div>
                            <div class="text">
                                Digital sign on glass. Online completed delivery status. App led extra services
                                provision. Online payment or cash on delivery supported. ​
                            </div>
                        </li>
                        <li class="gridLine">
                            <div class="iconBox">
                                <div class="icon"><img src="assets/icon43.svg"></div>
                            </div>
                            <div class="text">
                                Data feed for automated invoicing. ​
                            </div>
                        </li>
                        <li class="gridLine">
                            <div class="iconBox">
                                <div class="icon"><img src="assets/icon44.svg"></div>
                            </div>
                            <div class="text">
                                Easy to install and use software​
                            </div>
                        </li>
                    </ul>
                    <article class="mb-20 mtbD-30">
                        <div class="mb-20 mtbD-30">
                            Soon available on:
                        </div>
                        <img class="mr-5" width="130" src="assets/gPlay.svg">
                        <img width="130" src="assets/aStore.svg">
                    </article>
                </section>
            </section>
            <!-- Stopka -->
            <section class="footertop px-15">
                <div class="desctopContent">
                    <div class="mb-20 mt-10">
                        <b style="font-size: 18px;">Get in touch with us.</b>
                        <br><br>
                        Find out how we can improve the efficiency of your business. ​
                    </div>
                    <div class="mb-20">
                        <button class="contactBtn accentBg" (click)="contactUs()">
                            Contact Us​
                        </button>
                    </div>
                </div>
            </section>
            <!-- podziel się -->
            <section class="footerMiddle pt-10 px-15">
                <div class="desctopContent">
                    <div class="flex flexItemCenter mb-10">
                        <div class="icon"><img src="assets/m1.svg"></div>
                        <div class="ml-20">Download PDF</div>
                    </div>
                    <div class="flex flexItemCenter mb-10">
                        <div class="icon"><img src="assets/m2.svg"></div>
                        <div class="ml-20">Share</div>
                    </div>
                    <div class="flex flexItemCenter backButton">
                        <div class="icon"><img src="assets/m3.svg"></div>
                        <div class="ml-20" (click)="fullpage_api.moveTo('transport-companies', 0);">Back</div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <!-- O Firmie -->
    <div class="section coverImg" id="screen7">
        <div class="slide">
            <div class="gridEnd desktopHeaderLineBox ">
                <div class="topWrapper">
                    <div id="logoMenu" class="flex flexItemCenter saLogo px-15 pxD-35 ">
                        <div class="logoRotate2 mr-5">
                            <img src="../../../../assets/logo_icon.svg">
                        </div>
                        <div class="logoText">
                            <img src="../../../../assets/sa_text2.svg">
                        </div>
                    </div>
                    <div id="burgerMenu" class="flexRight px-15 pxD-35 ">
                        <div class="burger" (click)="openMenu()">
                            <div class="line1"></div>
                            <div class="short line2"></div>
                            <div class="line3"></div>
                        </div>
                    </div>
                </div>
                <div class="px-15 desktopPx-35">
                    <small class="hero">About SmarterApps</small>
                    <h3 class="mt-10 mb-20">
                        <span>W</span>E <span>U</span>NDERSTAND<br>
                        <span>L</span>OGISTICS & <span>T</span>ECH
                    </h3>
                    <section class="moreArrow" (click)="fullpage_api.moveTo('about-us', 1);">
                        We create smarter solutions improving mobile logistics transparency and efficiency. ​
                        <span class="arrowButton"><img src="../../../../assets/arrowN.svg"></span>
                    </section>
                </div>
            </div>
        </div>
        <!-- Szablon O Firmie -->
        <div class="slide slidePanel">
            <!-- Nagłówek & menu -->
            <section class="gridWraperLayout desctopContent mb-10 px-15">
                <div class="logo2">
                    <img src="assets/logo_ba.svg">
                </div>
                <div class="burger2" (click)="fullpage_api.moveTo('about-us', 0);">
                    <div class="line1"></div>
                    <div class="short line2"></div>
                    <div class="line3"></div>
                </div>
            </section>
            <section class="sectionImage mb-20" id="podTopImg7"></section>
            <section class="desctopContent px-15">
                <!-- Wstęp -->
                <section class="headerLine mb-20">
                    <div class="iconImg"></div>
                    <h3>About SmarterApps​</h3>
                </section>
                <section>
                    <article class="mb-20 mtbD-30">
                        <h2>Smarter Philosophy</h2>
                        We understand logistics and technology. We use this know-how to create pragmatic innovations
                        triggering mobile logistics optimization. First mile, yard operations, last mile, these are the
                        most expensive and most challenging to manage operations in the supply chains. We eliminate
                        inefficiencies in mobile operations management caused by legacy Supply Chain Execution systems.
                        We standardize, digitize and automate processes enabling dramatic improvement of logistic
                        operations efficiency. We deliver smarter solutions. We are SmarterApps.
                    </article>
                    <article class="mb-20 mtbD-30">
                        <h2>Company’s Milestones​</h2>
                    </article>
                </section>
                <!-- Rozwiązania dla dużych i małych firm -->
                <!-- <section class="sectionImage mb-20" id="podMiddImg2"></section> -->
                <ul class="logicList mb-20 mtbD-30">
                    <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon">
                                <div class="year">2015</div>
                                <div class="date">October</div>
                            </div>
                        </div>
                        <div class="text">
                            Company is founded. ​
                        </div>
                    </li>
                    <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon">
                                <div class="year">2016</div>
                                <div class="date">December</div>
                            </div>
                        </div>
                        <div class="text">
                            First international client onboarded. ​
                        </div>
                    </li>
                    <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon">
                                <div class="year">2017</div>
                                <div class="date">January</div>
                            </div>
                        </div>
                        <div class="text">
                            SmarterPOD launch​
                        </div>
                    </li>
                    <!-- <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon">
                                <div class="year">2017</div>
                                <div class="date">August</div>
                            </div>
                        </div>
                        <div class="text">
                            Pierwsze zakończone wdrożenie systemu SmarterPOD.
                        </div>
                    </li> -->
                    <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon">
                                <div class="year">2018</div>
                                <div class="date">June</div>
                            </div>
                        </div>
                        <div class="text">
                            SmarterPOD first implementation for global logistics operator​
                        </div>
                    </li>
                    <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon">
                                <div class="year">2018</div>
                            </div>
                        </div>
                        <div class="text">
                            SmarterYMS launch and the beginning of implementation for global logistics operator. ​
                        </div>
                    </li>
                    <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon">
                                <div class="year">2019</div>
                            </div>
                        </div>
                        <div class="text">
                            International scale up​
                        </div>
                    </li>
                </ul>
            </section>
            <!-- Stopka -->
            <section class="footertop px-15">
                <div class="desctopContent">
                    <div class="mb-20 mt-10">
                        <b style="font-size: 18px;">Get in touch with us.</b>
                        <br><br>
                        Find out how we can improve the efficiency of your business. ​
                    </div>
                    <div class="mb-20">
                        <button class="contactBtn accentBg" (click)="contactUs()">
                            Contact Us​
                        </button>
                    </div>
                </div>
            </section>
            <!-- podziel się -->
            <section class="footerMiddle pt-10 px-15">
                <div class="desctopContent">
                    <div class="flex flexItemCenter mb-10">
                        <div class="icon"><img src="assets/m1.svg"></div>
                        <div class="ml-20">Download PDF</div>
                    </div>
                    <div class="flex flexItemCenter mb-10">
                        <div class="icon"><img src="assets/m2.svg"></div>
                        <div class="ml-20">Share</div>
                    </div>
                    <div class="flex flexItemCenter backButton">
                        <div class="icon"><img src="assets/m3.svg"></div>
                        <div class="ml-20" (click)="fullpage_api.moveTo('about-us', 0);">Back</div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <!-- contact -->
    <div class="section coverImg" id="screen8">
        <div class="slide">
            <div class="gridEnd desktopHeaderLineBox ">
                <div class="topWrapper">
                    <div id="logoMenu" class="flex flexItemCenter saLogo px-15 pxD-35 ">
                        <div class="logoRotate2 mr-5">
                            <img src="../../../../assets/logo_icon.svg">
                        </div>
                        <div class="logoText">
                            <img src="../../../../assets/sa_text2.svg">
                        </div>
                    </div>
                    <div id="burgerMenu" class="flexRight px-15 pxD-35 ">
                        <div class="burger" (click)="openMenu()">
                            <div class="line1"></div>
                            <div class="short line2"></div>
                            <div class="line3"></div>
                        </div>
                    </div>
                </div>
                <div class="px-15 desktopPx-35">
                    <small class="hero">SmarterApps</small>
                    <h3 class="mt-10 mb-40">Contact Us​</h3>
                    <section>
                        <article class="mb-20 mtbD-30">
                            <h4>We are happy to answer your questions​</h4>
                            <div class="icon_contact mb-10">
                                <img class="mr-10" src="../../../../assets/icon_mail.svg">
                                business@smarterpod.eu
                            </div>
                            <div class="icon_contact mb-10">
                                <img class="mr-10" src="../../../../assets/icon_phone.svg">
                                +48 501 000 355
                            </div>
                        </article>
                        <article class="mb-20 mtbD-30">
                            <h4>Warsaw Office</h4>
                            <div class="icon_contact mb-10">
                                <img class="mr-10" src="../../../../assets/icon_mail.svg">
                                office@smarterapps.eu
                            </div>
                            <div class="icon_contact mb-10">
                                <img class="mr-10" src="../../../../assets/icon_phone.svg">
                                +48 (22) 290 00 02
                            </div>
                        </article>
                        <article class="mb-20 mtbD-30">
                            <h4>Company Registration Details​</h4>
                            <div class="mb-5">SmarterApps Sp. z o.o. </div>
                            <div class="mb-5">Ul. Ostrobramska 79 </div>
                            <div class="mb-5">04-175 Warszawa </div>
                            <div class="mb-5">NIP: PL 9522140414</div>
                            <div class="mb-5">KRS: 0000582511 </div>
                        </article>
                        <article class="mt-20 moreArrow" (click)="fullpage_api.moveTo('contact', 1);">
                            Privacy Policy
                            <span><img width="15" height="15" src="../../../../assets/arrowN.svg"></span>
                        </article>
                    </section>
                </div>
                <!-- <app-footer-en></app-footer-en> -->
            </div>
            <div class="socialShare">
                <div class="fb-share-button" data-href="https://test.smarterapps.eu/" data-layout="button_count"
                    data-size="small">
                    <a target="_blank"
                        href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ftest.smarterapps.eu%2F&amp;src=sdkpreparse"
                        class="fb-xfbml-parse-ignore">
                        <img src="../../../../assets/fb.svg">
                    </a>
                </div>
                <div><img src="../../../../assets/in.svg" style="opacity: 0.1;"></div>
                <div><img src="../../../../assets/wapp.svg" style="opacity: 0.1;"></div>
                <div><img src="../../../../assets/msg.svg" style="opacity: 0.1;"></div>
                <div><img src="../../../../assets/icon_mail.svg" style="opacity: 0.1;"></div>
            </div>
        </div>
        <!-- Szablon Polityka prywatności -->
        <div class="slide slidePanel">
            <section class="desctopContent px-15">
                <!-- Nagłówek & menu -->
                <section class="gridWraperLayout mb-10">
                    <div class="logo2">
                        <img src="assets/logo_ba.svg">
                    </div>
                    <div class="burger2" (click)="fullpage_api.moveTo('contact', 0);">
                        <div class="line1"></div>
                        <div class="short line2"></div>
                        <div class="line3"></div>
                    </div>
                </section>
                <!-- Wstęp -->
                <section class="headerLine2 mb-20">
                    <h3>Polityka prywatności</h3>
                </section>
                <section>
                    <article class="mb-20">
                        I. Definitions<br><br>

                        Controller - Smarter Apps Sp. z o.o. with its registered office in Warsaw, 79 Ostrobramska
                        Street, entered into the Register of Entrepreneurs of the National Court Register kept by the
                        District Court for the Capital City of Warsaw in Warsaw, XIII Commercial Division of the
                        National Court Register under KRS number 0000582511, NIP: 9522140414, Regon: 362815150, which
                        provides services by electronic means and stores and accesses information in the User's
                        devices.<br><br>

                        Cookies - means IT data, in particular small text files, saved and stored on the User’s devices
                        through which the User uses the websites of the Service.<br><br>

                        Controller’s Cookies (Own Cookies) - means Cookies placed by the Controller, related to
                        providing services by electronic means by the Controller via the Service.<br><br>

                        External Cookies - means Cookies placed by the Controller's partners, via the
                        Service.<br><br>

                        Service - means the website or web application used by the Controller to provide the Service, in
                        the domain smarterapps.eu.<br><br>

                        Device - means an electronic device through which the User gains access to the
                        Service.<br><br>

                        User - means an entity for which, in accordance with the Rules and Regulations and applicable
                        law, services may be provided by electronic means or who can enter into an Agreement for the
                        provision of services by electronic means.<br><br>

                        II. Types of used Cookies<br><br>

                        Cookies used by the Controller are safe for the User’s Device. In particular, it is impossible
                        to transfer viruses, or other unwanted or malicious software to User's Device via this channel.
                        These files allow to identify the software used by the User and adapt the Service to each User
                        individually. Cookies usually contain the name of the domain they come from, their storage time
                        on the Device and the assigned value.<br><br>

                        The Controller uses two types of cookies:<br><br>

                        Session cookies: they are stored on the User’s Device and remain there until the end of a given
                        browser session. The stored information is then permanently deleted from the Device memory. The
                        mechanism of session cookies does not allow to download any personal data or any confidential
                        information from the User's Device.<br><br>

                        Persistent cookies: they are stored on the User’s Device and remain there until they are
                        deleted. Ending a given browser session or turning off the Device does not delete them from the
                        User's Device. The mechanism of persistent cookies does not allow to download any personal data
                        or any confidential information from the User’s Device.<br><br>

                        The User can restrict or turn off Cookies' access to the Device. In such case, the User will
                        still be able to use the Service, except for functions that require cookies.<br><br>

                        III. Purposes for which Cookies are used<br><br>

                        The Controller uses Own Cookies for the following purposes:<br><br>

                        (a) Service configuration<br><br>

                        to adjust the content of the Service websites to the User's preferences and optimize the use of
                        the Service websites.<br><br>

                        to recognise the Device of the Service User and its location and, accordingly, display the
                        website, adjusted to their individual needs;<br><br>

                        to remember the settings selected by the User and to personalise the User’s interface, e.g. in
                        terms of the selected language or region of the User, remember the history of visited pages in
                        the Service in order to recommend the content.<br><br>

                        (b) Implementation of processes necessary for the full functionality of the websites<br><br>

                        to adjust the content of the Service websites to the User's preferences and optimize the use of
                        the Service websites. In particular, these files allow us to understand the basic parameters of
                        the User's Device and properly display the website tailored to their individual
                        needs.<br><br>

                        (c) Remember the User’s location<br><br>

                        to properly configure the selected functions of the Service, in particular rendering it possible
                        to adjust the provided information to the User, including their location;<br><br>

                        d) Analysis, research and audience ratings in order to create anonymous statistics which help to
                        understand how the Service Users use the Service websites, which allows for the improvement of
                        their structure and contents;<br><br>

                        (e) Ensure the safety and reliability of the Service.<br><br>

                        The Service Controller uses External Cookies for the following purposes:<br><br>

                        (a) to collect general and anonymous static data via analytical tools:<br><br>

                        Google Analytics [Cookies Controller: Google Inc., with its registered office in the
                        USA]<br><br>

                        (b) to use their interactive functions in order to make the Service more popular in the social
                        media:<br><br>

                        twitter.com [Cookies Controller: Twitter Inc. with its registered office in the USA]<br><br>

                        plus.google.com [Cookies Controller: Google Inc. with its registered office in the
                        USA]<br><br>

                        Facebook.com [Cookie Controller: Facebook Inc. with its registered office in the USA or Facebook
                        Ireland with its registered office in Ireland] LinkedIn.com [Cookie Controller: LinkedIn Ireland
                        Limited with its registered office in Ireland]<br><br>

                        IV. Possibility of determining the conditions of storage or access by Cookies<br><br>

                        The User may independently and at any time change the settings for Cookies, specifying the
                        conditions for their storage and access to Cookies on the User's Device. Changes to the settings
                        referred to in the previous sentence can be made by the User via the web browser settings or by
                        using the service configuration. These settings can be changed in particular in such a way as to
                        block the automatic<br><br>

                        handling of Cookies in the web browser settings or to inform about them every time they are
                        placed on the User's device. Detailed information about the possibilities and ways of handling
                        Cookies is available at the software (web browser) settings. The User may delete cookies at any
                        time by using the features available in the used web browser. Restricting the use of Cookies may
                        affect some of the functions available on the Service website.<br><br>
                    </article>
                </section>
            </section>
        </div>
    </div>
</div>
<div class="topMenu" [ngClass]="{'visMenu':showMenu}">
    <ul id="menu" class="menu h-100">
        <div class="flex flexItemCenter spaceBetween pb-10 menuLogo">
            <div class="flex flexItemCenter saLogo">
                <div class="logoRotate3 mr-5">
                    <img src="../../../../assets/logo_icon.svg">
                </div>
                <div style="width: 120px;margin-top: 3px;">
                    <img src="../../../../assets/sa_text2.svg">
                </div>
            </div>
            <div class="flexRight">
                <div class="burger burgerOpen" (click)="closeMenu()">
                    <div class="line1"></div>
                    <div class="short line2"></div>
                    <div class="line3"></div>
                </div>
            </div>
        </div>
        <li data-menuanchor="home" class="listLinkItem active" (click)="closeMenu()">
            <a class="itemLink" href="#home">
                Home <span><img width="20" height="20" src="../../../../assets/arrow2.svg"></span>
            </a>
        </li>
        <li data-menuanchor="pod" class="listLinkItem" (click)="closeMenu()">
            <a class="itemLink" href="#pod">
                SmarterPOD <span><img width="20" height="20" src="../../../../assets/arrow2.svg"></span>
            </a>
        </li>
        <li data-menuanchor="yms" class="listLinkItem" (click)="closeMenu()">
            <a class="itemLink" href="#yms">
                SmarterYMS <span><img width="20" height="20" src="../../../../assets/arrow2.svg"></span>
            </a>
        </li>
        <li data-menuanchor="logistics-operators" class="listLinkItem" (click)="closeMenu()">
            <a class="itemLink" href="#logistics-operators">
                Logistics Operators <span><img width="20" height="20" src="../../../../assets/arrow2.svg"></span>
            </a>
        </li>
        <li data-menuanchor="distributors-and-manufacturers" class="listLinkItem" (click)="closeMenu()">
            <a class="itemLink" href="#distributors-and-manufacturers">
                Distributors and manufacturers
                ​<span>
                    <img width="20" height="20" src="../../../../assets/arrow2.svg">
                </span>
            </a>
        </li>
        <li data-menuanchor="transport-companies" class="listLinkItem" (click)="closeMenu()">
            <a class="itemLink" href="#transport-companies">
                Transport Companies
                ​<span>
                    <img width="20" height="20" src="../../../../assets/arrow2.svg">
                </span>
            </a>
        </li>
        <li data-menuanchor="about-us" class="listLinkItem" (click)="closeMenu()">
            <a class="itemLink" href="#about-us">
                About Us <span><img width="20" height="20" src="../../../../assets/arrow2.svg"></span>
            </a>
        </li>
        <div class="pt-15 pb-10"
            style="color: #fff;font-size: 12px; font-weight:bold;border-bottom: 1px solid #fff;border-top: 1px solid #fff;display: grid; grid-template-columns: 1fr 1fr;">
            Change the language to:
            <!-- Social Media -->
            <!-- <div>
                <img class="mr-10" width="20" src="../../../../assets/fb.svg">
                <img class="mr-10" width="20" src="../../../../assets/in.svg">
                <img class="mr-10" width="20" src="../../../../assets/wapp.svg">
                <img class="mr-10" width="20" src="../../../../assets/msg.svg">
                <img class="mr-10" width="20" src="../../../../assets/mail.svg">
            </div> -->
            <div style="display: flex; align-items: center; justify-content: flex-end;">
                <img src="assets/country/pl.svg" width="20" alt="country" style="margin-right: 5px;">
                <a routerLink="/">Polski</a>
            </div>
        </div>
        <div data-menuanchor="contact" class="listLinkItem" (click)="closeMenu()">
            <a class="itemLink mb-5" href="#contact">
                <div class="mb-30 contactDesc">Check how we can digitize logistics management in yours
                    business.</div>
                <div class="text-center">
                    <button class="msgBtn mb-20">Contact with Us</button>
                </div>
            </a>
        </div>
    </ul>
</div>