<div class="floatCloseButton" (click)="fullpage_api.moveSlideLeft();">
    <div class="icon">
        <img src="assets/m4.svg">
    </div>
    <div class="mt-10">Powrót</div>
</div>
<div fullpage id="fullpage" [options]="config" (ref)="getRef($event)">
    <!-- Łańcuch dostaw -->
    <div class="section coverImg" id="screen1">
        <div class="slide">
            <div class="topWrapper">
                <div id="logoMenu" class="flex flexItemCenter saLogo px-15 pxD-35 ">
                    <div class="logoRotate2 mr-5">
                        <img src="assets/logo_icon.svg">
                    </div>
                    <div class="logoText">
                        <img src="assets/sa_text2.svg">
                    </div>
                </div>
                <div id="burgerMenu" class="flexRight px-15 pxD-35 ">
                    <div class="burger" (click)="openMenu()">
                        <div class="line1"></div>
                        <div class="short line2"></div>
                        <div class="line3"></div>
                    </div>
                </div>
            </div>
            <div class="gridEnd desktopHeaderLineBox ">
                <div class="px-15 desktopPx-35">
                    <h3 class="mt-0 mb-20"><span>D</span>igitalizujemy<br> zarządzanie<br>
                        logistyką
                    </h3>
                    <section class="moreArrow" (click)="fullpage_api.moveTo('home', 1);">
                        Od podjęcia przesyłki poprzez operacje w centrach logistycznych, do doręczenia do odbiorcy
                        finalnego.
                        <span class="arrowButton"><img src="assets/arrowN.svg"></span>
                    </section>
                </div>
                <!-- Scroll Mouse -->
                <div class="scroll-downs">
                    <div class="mousey">
                        <div class="scroller"></div>
                    </div>
                    Scroll Down
                </div>
                <div class="shortStory halfGrid">
                    <div class="halfGrid">
                        <div>
                            <div class="number">
                                <div class="topNumber">235</div>
                                <div class="text">tysięcy</div>
                            </div>
                            <div class="descNumber">
                                kierowców zostało dotychczas<br> obsłużonych przez nasze systemy
                            </div>
                        </div>
                        <div>
                            <div class="number">
                                <div class="topNumber">2.7</div>
                                <div class="text">milionów</div>
                            </div>
                            <div class="descNumber">
                                dostaw LTL i FTL zostało obsłużonych przez<br> SmarterPOD w ciągu ostatnich 12
                                miesięcy
                            </div>
                        </div>
                    </div>
                    <div class="map">
                        <div class="mb-20 mapTitle">Międzynarodowy zasięg</div>
                        <div class="description mb-20">
                            Obsługujemy operatorów logistycznych i lokalnych <br> producentów z wielu krajów
                            Europy.<br><br>
                            Nasz system wspiera 12 języków i transakcje<br> płatnicze w 6 walutach.
                        </div>
                        <button class="moreInfo" (click)="fullpage_api.moveTo('o-firmie', 1);">Dowiedz się o nas
                            więcej!</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="slide">
            <div class="slidePanel">
                <section class="gridWraperLayout desctopContent mb-10 pt-15 pb-15">
                    <div class="logo2">
                        <img src="assets/logo_ba.svg">
                    </div>
                    <div class="burger2" (click)="fullpage_api.moveTo('home', 0);">
                        <div class="line1"></div>
                        <div class="short line2"></div>
                        <div class="line3"></div>
                    </div>
                </section>
                <section class="sectionImage mb-20" id="podTopImg1"></section>
                <div class="desctopContent px-15">
                    <!-- Wstęp -->
                    <section class="headerLine mb-20">
                        <div class="iconImg"></div>
                        <h3>Digitalizujemy logistykę</h3>
                    </section>
                    <article class="mb-20 mtbD-30">
                        <b>SmarterApps</b> dostarcza systemy digitalizujące zarządzanie logistyką mobilną. W całym
                        łańcuchu dostaw, od załadunku u dostawcy, poprzez operacje w centrach logistycznych, po finalną
                        dostawę, są krytyczne z perspektywy czasu i kosztów realizacji dostawy.
                    </article>
                    <article class="mb-20 mtbD-30">
                        <b>Digitalizujemy zarządzanie logistyką mobilną:</b>
                    </article>
                    <div class="mb-20 mtbD-30">
                        <ul class="logicList">
                            <li class="gridLine">
                                <div class="iconBox2">
                                    <div class="icon"><img src="assets/icon11.svg"></div>
                                </div>
                                <div class="text">
                                    Standaryzujemy, optymalizujemy i automatyzujemy procesy.
                                </div>
                            </li>
                            <li class="gridLine">
                                <div class="iconBox2">
                                    <div class="icon"><img src="assets/icon12.svg"></div>
                                </div>
                                <div class="text">
                                    Wspieramy kierowców w realizacji zadań, prowadząc ich zgodnie z ustalonym procesem.
                                </div>
                            </li>
                            <li class="gridLine">
                                <div class="iconBox2">
                                    <div class="icon"><img src="assets/icon13.svg"></div>
                                </div>
                                <div class="text">
                                    Wspieramy pracowników centrów logistycznych, obsługę ramp, portierów, magazynierów,
                                    w
                                    optymalnej realizacji procesów przy załadunku i rozładunku, na parkingach i placach
                                    manewrowych oraz podczas awizacji kierowców.
                                </div>
                            </li>
                            <li class="gridLine">
                                <div class="iconBox2">
                                    <div class="icon"><img src="assets/icon14.svg"></div>
                                </div>
                                <div class="text">
                                    Umożliwiamy dyspozytorom monitorowanie realizowanych zadań w czasie rzeczywistym i
                                    natychmiastową reakcję na wypadek niepożądanych zdarzeń.
                                </div>
                            </li>
                            <li class="gridLine">
                                <div class="iconBox2">
                                    <div class="icon"><img src="assets/icon15.svg"></div>
                                </div>
                                <div class="text">
                                    Zapobiegamy nieprawidłowościom i opóźnieniom proaktywnie informując kierowców i
                                    dyspozytorów o potencjalnych zagrożeniach i możliwościach przeciwdziałania.
                                </div>
                            </li>
                            <li class="gridLine">
                                <div class="iconBox2">
                                    <div class="icon"><img src="assets/icon16.svg"></div>
                                </div>
                                <div class="text">
                                    Raportujemy zarządzającym logistyką, na bieżąco, cyklicznie i ad-hoc, realizację
                                    planów,
                                    KPI, nieprawidłowości i ich przyczyny.
                                </div>
                            </li>
                            <li class="gridLine">
                                <div class="iconBox2">
                                    <div class="icon"><img src="assets/icon17.svg"></div>
                                </div>
                                <div class="text">
                                    Informujemy w czasie rzeczywistym klientów i odbiorców o statusie realizowanych dla
                                    nich
                                    dostaw.
                                </div>
                            </li>
                        </ul>
                    </div>
                    <article class="mb-20 mtbD-30">
                        <b>Podnosimy jakość i wydajność operacji. Obniżamy koszty logistyki.</b>
                        <br><br>
                        Obsługujemy zarówno międzynarodowych operatorów logistycznych jak i lokalne firmy realizujących
                        logistykę
                        samodzielnie.
                    </article>
                </div>
                <!-- Stopka -->
                <section class="footertop px-15">
                    <div class="desctopContent">
                        <div class="mb-20 mt-10">
                            <b style="font-size: 18px;">Współpracuj z nami</b>
                            <br><br>
                            Daj nam znać jak możemy pomóc twojej firmie. Odpowiemy niezwłocznie.
                        </div>
                        <div class="mb-20">
                            <button class="contactBtn accentBg" (click)="contactUs()">
                                Skontaktuj się z nami
                            </button>
                        </div>
                    </div>
                </section>
                <!-- podziel się -->
                <section class="footerMiddle pt-10 px-15">
                    <div class="desctopContent">
                        <div class="flex flexItemCenter mb-10">
                            <div class="icon"><img src="assets/m1.svg"></div>
                            <div class="ml-20">Pobierz PDF</div>
                        </div>
                        <div class="flex flexItemCenter mb-10">
                            <div class="icon"><img src="assets/m2.svg"></div>
                            <div class="ml-20">Prześlij znajomemu</div>
                        </div>
                        <div class="flex flexItemCenter backButton">
                            <div class="icon"><img src="assets/m3.svg"></div>
                            <div class="ml-20" (click)="fullpage_api.moveTo('home', 0);">Powrót</div>
                        </div>
                    </div>
                </section>
                <app-footer></app-footer>
            </div>
        </div>
    </div>
    <!-- POD -->
    <div class="section coverImg" id="screen2">
        <!-- Zajawka POD -->
        <div class="slide">
            <div class="topWrapper">
                <div id="logoMenu" class="flex flexItemCenter saLogo px-15 pxD-35 ">
                    <div class="logoRotate2 mr-5">
                        <img src="assets/logo_icon.svg">
                    </div>
                    <div class="logoText">
                        <img src="assets/sa_text2.svg">
                    </div>
                </div>
                <div id="burgerMenu" class="flexRight px-15 pxD-35 ">
                    <div class="burger" (click)="openMenu()">
                        <div class="line1"></div>
                        <div class="short line2"></div>
                        <div class="line3"></div>
                    </div>
                </div>
            </div>
            <div class="gridEnd desktopHeaderLineBox ">
                <div class="px-15 desktopPx-35">
                    <small class="hero">
                        Smarter<span>POD</span>
                    </small>
                    <h3 class="mt-10 mb-20"><span>Z</span>arządzanie <br> dostawą w
                        czasie<br>rzeczywistym</h3>
                    <section class="moreArrow" (click)="fullpage_api.moveTo('pod', 1);">
                        Wspieramy kierowców i dyspozytorów w realizacji zadań operacyjnych, monitorujemy i
                        automatyzujemy procesy dostawy.
                        <span class="arrowButton"><img src="assets/arrowN.svg"></span>
                    </section>
                </div>
            </div>
        </div>
        <!-- Szablon POD -->
        <div class="slide slidePanel">
            <!-- Nagłówek & menu -->
            <section class="gridWraperLayout desctopContent mb-10 px-15">
                <div class="logo2">
                    <img src="assets/logo_ba.svg">
                </div>
                <div class="burger2" (click)="fullpage_api.moveTo('pod', 0);">
                    <div class="line1"></div>
                    <div class="short line2"></div>
                    <div class="line3"></div>
                </div>
            </section>
            <section class="sectionImage mb-20" id="podTopImg2"></section>
            <section class="desctopContent px-15 desctop40">
                <!-- Wstęp -->
                <section class="headerLine mb-20">
                    <div class="iconImg"></div>
                    <h3>SmarterPOD</h3>
                </section>
                <article class="mb-20 mtbD-30">
                    <b>SmarterPOD</b> to rozwiązanie klasy „Proof of Delivery” umożliwiające zarządzanie i kontrolę
                    nad pełnym procesem dostawy w czasie rzeczywistym. Aplikacja wspiera kierowcę w realizacji zadań,
                    poprzez automatyzacje i standaryzację procesów. System monitoruje, zarządza i automatyzuje procesy
                    logistyczne dostawy, których operatorem jest kierowca.
                </article>
                <!-- Rozwiązania dla dużych i małych firm -->
                <section class="desctopContent sectionImage " id="podMiddImg"></section>
                <ul class="logicList mb-20 mtbD-30">
                    <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon18.svg"></div>
                        </div>
                        <div class="text">
                            <b>Zdigitalizowany plan trasy</b>
                            Plan trasy dostępny dla kierowcy w aplikacji, przed rozpoczęciem pracy, aktualizowany na
                            bieżąco.
                        </div>
                    </li>
                    <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon2.svg"></div>
                        </div>
                        <div class="text"><b>Załadunek</b>
                            Aplikacja poprzez zdefiniowane procesy i przy wsparciu skanera, asystuje kierowcy w
                            załadunku, znacząco przyspiesza proces i eliminuje potencjalne błędy.
                        </div>
                    </li>
                    <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon3.svg"></div>
                        </div>
                        <div class="text">
                            <b>Realizacja trasy</b>
                            Aplikacja przy wsparciu nawigacji, prowadzi do klientów, ostrzega o utrudnieniach i
                            możliwych opóźnieniach, awizuje dostawy.
                        </div>
                    </li>
                    <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon4.svg"></div>
                        </div>
                        <div class="text">
                            <b>Dostawa do klientów</b>
                            Aplikacja umożliwia elektroniczne potwierdzenie odbioru w czasie rzeczywistym, mobilne
                            płatności, wspiera kierowców w realizacji usług dodatkowych oraz w prewencji lub obsłudze
                            reklamacji. System automatyzuje wystawianie faktur za zrealizowane usługi.
                        </div>
                    </li>
                    <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon5.svg"></div>
                        </div>
                        <div class="text">
                            <b>Odbiór od klientów</b>
                            Aplikacja prowadzi przez proces przyjmowana zwrotów i nadawania przesyłek.
                        </div>
                    </li>
                    <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon7.svg"></div>
                        </div>
                        <div class="text">
                            <b>Rozładunek na magazynie</b>
                            Rozładunek przy asyście aplikacji w rozładunku zwrotów lub nadanych przesyłek.
                        </div>
                    </li>
                    <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon37.svg"></div>
                        </div>
                        <div class="text">
                            <b>Rozliczanie Trasy</b>
                            Saldo zrealizowanych zadań dodatkowych, rozliczenie kilometrówki, gotówki i płatności
                            mobilnych.
                        </div>
                    </li>
                </ul>
                <!-- Kluczowe procesy dostawy -->
                <article class="mb-20 mtbD-30">
                    <b>SmarterPOD</b> wspiera różne obszary biznesowe w organizacji:
                </article>
                <section>
                    <div class="postArea mb-20 mtbD-30">
                        <div class="postButtons">
                            <div class="postButtonsItem">
                                <div>Dyspozytor / Planista</div>
                            </div>
                            <div class="descItem mt-20">
                                <ul class="deliveryProcessList">
                                    <li class="mb-10">
                                        Monitoring w czasie rzeczywistym parametrów realizowanych zadań, ze szczegółowym
                                        podglądem danych
                                        zlecenia, pracy kierowcy, lokalizacji dostawy, postępu realizacji i dynamicznym
                                        filtrowaniem danych.
                                    </li>
                                    <li class="mb-10">
                                        Automatyczne powiadomienia i alerty, w tym, komunikaty zapobiegające
                                        nieprawidłowościom, np.
                                        potencjalnym opóźnieniom.
                                    </li>
                                    <li class="mb-10">
                                        Zarządzanie trasą, w tym podgląd i analiza realizowanego procesu dostawy.
                                    </li>
                                    <li class="mb-10">
                                        Zarządzanie kierowcami, w tym, szczegółowe dane dotyczące kierowców, tworzenie i
                                        modyfikowanie
                                        „kierowców” i przypisywanie im uprawnień.
                                    </li>
                                    <li class="mb-10">
                                        Potwierdzenie odbioru, informacja online potwierdzająca zrealizowanie dostawy,
                                        pobranie podpisu
                                        odbiorcy na urządzeniu, realizacja usług dodatkowych, pobranie płatności,
                                        zasilenie online systemów
                                        do fakturowania.
                                    </li>
                                    <li class="mb-10">
                                        Zarządzanie zdjęciami z realizowanych zadań, w tym, zdjęcia dokumentów, zdjęcia
                                        uszkodzonych
                                        przesyłek, automatyczne rekomendacje i procesy reakcji.
                                    </li>
                                    <li class="mb-10">
                                        Dynamiczne raportowanie i filtrowanie danych.
                                    </li>
                                    <li class="mb-10">
                                        Zarządzanie kontaktami operacyjnymi, punktami odbioru, zleceniami
                                        transportowymi, raportami,
                                        uprawnieniami do systemu, procesami, zadaniami, etc.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="postButtons">
                            <div class="postButtonsItem">
                                <div>Administracja transportu / Back Office</div>
                            </div>
                            <div class="descItem mt-20">
                                <ul class="deliveryProcessList">
                                    <li class="mb-10">
                                        Automatyzacja i digitalizacja danych potwierdzających realizację usługi (skany
                                        dokumentów
                                        przewozowych).
                                    </li>
                                    <li class="mb-10">
                                        Zarządzanie zdjęciami z realizowanych zadań, w tym, zdjęcia dokumentów, zdjęcia
                                        uszkodzonych
                                        przesyłek, opisy reklamacji.
                                    </li>
                                    <li class="mb-10">
                                        Zarządzanie gospodarką paletową (salda paletowe).
                                    </li>
                                    <li class="mb-10">
                                        Moduł zarządzania reklamacjami z narzędziem do komunikacji wewnętrznej.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="postButtons">
                            <div class="postButtonsItem">
                                <div>Dział obsługi klienta </div>
                            </div>
                            <div class="descItem mt-20">
                                <ul class="deliveryProcessList">
                                    <li class="mb-10">
                                        Automatyczne powiadomienia o potencjalnych opóźnieniach i przetrzymaniach.
                                    </li>
                                    <li class="mb-10">
                                        Zarządzanie przesyłką, w tym, bieżąca lokalizacja online, monitoring parametrów
                                        pracy dostaw,
                                        szczegółowe informacje o przesyłkach.
                                    </li>
                                    <li class="mb-10">
                                        Bieżąca informacja o pojawieniu się reklamacji towarowej lub paletowej.
                                    </li>
                                    <li class="mb-10">
                                        Potwierdzenie odbioru, informacje online potwierdzająca zrealizowanie dostawy,
                                        pobranie podpisu
                                        odbiorcy na urządzeniu, realizacja usług dodatkowych, pobranie płatności,
                                        zasilenie online systemów
                                        do fakturowania.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="postButtons">
                            <div class="postButtonsItem">
                                <div>Zarządzanie transportem </div>
                            </div>
                            <div class="descItem mt-20">
                                <ul class="deliveryProcessList">
                                    <li class="mb-10">
                                        Bieżące raportowanie realizacji dostaw, fakturowanie, wpływów, etc.
                                    </li>
                                    <li class="mb-10">
                                        Raportowanie nieprawodłowości, np. opóźnień, reklamacji, wraz z przyczynami ich
                                        wystąpienia.
                                    </li>
                                    <li class="mb-10">
                                        Budowa spersonalizowanych raportów w zakresie typu i obszaru danych wraz z
                                        definicją cykliczności
                                        raportów.
                                    </li>
                                    <li class="mb-10">
                                        Definicja własnych wskaźników KPI i statystyk pomiaru efektywności realizacji
                                        dostaw.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <article class="mb-20 mtbD-30">
                        <b>SmarterPOD</b> to rozwiązanie dla firm logistycznych oraz firm
                        realizujących logistykę samodzielnie, na przykład, ze względu na specyfikę wytwarzanego
                        produktu.
                    </article>
                    <div class="mb-20 mtbD-30">
                        <ul class="logicList2">
                            <li class="gridLine">
                                <div class="iconBox2">
                                    <div class="icon"><img src="assets/icon9.svg"></div>
                                </div>
                                <div class="text">
                                    <b>Operatorzy logistyczni</b>
                                    Nasze rozwiązanie kierujemy do dużych firm logistycznych. Realizujemy kontrakty
                                    z klientami z
                                    Polski, Francji, Czech i z Włoch, realizujemy także wdrażamy obecnie SMarterPOD u
                                    klientów z wielu krajów Europy.
                                </div>
                            </li>
                            <li class="gridLine">
                                <div class="iconBox2">
                                    <div class="icon"><img src="assets/icon10.svg"></div>
                                </div>
                                <div class="text">
                                    <b>Firmy realizujące logistykę samodzielnie</b>
                                    Nasze rozwiązanie jest gotowe do wsparcia procesów dostawy produktów o
                                    specyficznych wymogach
                                    transportu jak np. wymiary, sposób ładowania, wymagania taboru, temperatura i
                                    wilgotność przewozu, i
                                    wiele innych.
                                    <br> <br>
                                    Obecnie wspieramy zarządzanie dostawami dużych producentów i dystrybutorów na
                                    rynkach Europy
                                    środkowo-wschodniej.
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
            </section>
            <!-- Stopka -->
            <section class="footertop px-15">
                <div class="desctopContent">
                    <div class="mb-20 mt-10">
                        <b style="font-size: 18px;">Współpracuj z nami</b>
                        <br><br>
                        Daj nam znać jak możemy pomóc twojej firmie. Odpowiemy niezwłocznie.
                    </div>
                    <div class="mb-20">
                        <button class="contactBtn accentBg" (click)="contactUs()">
                            Skontaktuj się z nami
                        </button>
                    </div>
                </div>
            </section>
            <!-- podziel się -->
            <section class="footerMiddle pt-10 px-15">
                <div class="desctopContent">
                    <div class="flex flexItemCenter mb-10">
                        <div class="icon"><img src="assets/m1.svg"></div>
                        <div class="ml-20">Pobierz PDF</div>
                    </div>
                    <div class="flex flexItemCenter mb-10">
                        <div class="icon"><img src="assets/m2.svg"></div>
                        <div class="ml-20">Prześlij znajomemu</div>
                    </div>
                    <div class="flex flexItemCenter backButton">
                        <div class="icon"><img src="assets/m3.svg"></div>
                        <div class="ml-20" (click)="fullpage_api.moveTo('pod', 0);">Powrót</div>
                    </div>
                </div>
            </section>
            <app-footer></app-footer>
        </div>
    </div>
    <!-- YMS -->
    <div class="section coverImg" id="screen3">
        <div class="slide mainScreen">
            <div class="topWrapper">
                <div id="logoMenu" class="flex flexItemCenter saLogo px-15 pxD-35 ">
                    <div class="logoRotate2 mr-5">
                        <img src="assets/logo_icon.svg">
                    </div>
                    <div class="logoText">
                        <img src="assets/sa_text2.svg">
                    </div>
                </div>
                <div id="burgerMenu" class="flexRight px-15 pxD-35 ">
                    <div class="burger" (click)="openMenu()">
                        <div class="line1"></div>
                        <div class="short line2"></div>
                        <div class="line3"></div>
                    </div>
                </div>
            </div>
            <div class="gridEnd desktopHeaderLineBox ">
                <div class="px-15 desktopPx-35">
                    <small class="hero black white">Smarter<span>YMS</span></small>
                    <!-- <h3 class="mt-10 mb-20"><span>D</span>igitalizujemy<br> i <span>a</span>utomatyzujemy operacje <br> w
                        <span>m</span>agazynach i centrach<br> <span>l</span>ogistycznych</h3> -->
                    <h3 class="mt-10 mb-20">
                        <span>D</span>igitalizujemy operacje w centrach <br> logistycznych
                    </h3>
                    <section class="moreArrow" (click)="fullpage_api.moveTo('yms', 1);">
                        Zarządzamy awizacjami, ruchem pojazdów na placu, planem załadunków i komunikacją z kierowcami.
                        <span class="arrowButton"><img src="assets/arrowN.svg"></span>
                    </section>
                </div>
                <div class="shortStory halfGrid">
                    <div class="halfGrid">
                        <div class="iconTextBlock">
                            <div class="icon">
                                <img src="assets/awi.svg">
                            </div>
                            <div class="content">
                                <div class="header">
                                    AWIZACJE
                                </div>
                                <div class="text">
                                    Umożliwiamy pre-awizację i zarządzanie awizacjami w czasie rzeczywistym.
                                </div>
                            </div>
                        </div>
                        <div class="iconTextBlock">
                            <div class="icon">
                                <img src="assets/ope.svg">
                            </div>
                            <div class="content">
                                <div class="header">
                                    OPERACJE
                                </div>
                                <div class="text">
                                    Zarządzamy operacjami pojazdów na terenie centrum dystrybucji, na bramach,
                                    parkingach, placach
                                    manewrowych i rampach.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="halfGrid">
                        <div class="iconTextBlock">
                            <div class="icon">
                                <img src="assets/zas.svg">
                            </div>
                            <div class="content">
                                <div class="header">
                                    ZASOBY
                                </div>
                                <div class="text">
                                    Zarządzamy w czasie rzeczywistym zasobami parku logistycznego, tj. pracowników
                                    obsługi, ramp i
                                    urządzeń.
                                </div>
                            </div>
                        </div>
                        <div class="iconTextBlock">
                            <div class="icon">
                                <img src="assets/aut.svg">
                            </div>
                            <div class="content">
                                <div class="header">
                                    AUTOMATYZACJA
                                </div>
                                <div class="text">
                                    Automatyzujemy procesy identyfikacji i weryfikacji kierowców oraz komunikacji zadań
                                    do zrealizowania
                                    na terenie centrum logistycznego.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Szablon YMS -->
        <div class="slide slidePanel">
            <!-- Nagłówek & menu -->
            <section class="gridWraperLayout desctopContent mb-10 px-15">
                <div class="logo2">
                    <img src="assets/logo_ba.svg">
                </div>
                <div class="burger2" (click)="fullpage_api.moveTo('yms', 0);">
                    <div class="line1"></div>
                    <div class="short line2"></div>
                    <div class="line3"></div>
                </div>
            </section>
            <section class="sectionImage mb-20" id="podTopImg3"></section>
            <section class="desctopContent px-15">
                <!-- Wstęp -->
                <section class="headerLine mb-20">
                    <div class="iconImg"></div>
                    <h3>SmarterYMS</h3>
                </section>
                <section>
                    <article class="mb-20 mtbD-30">
                        <b>SmarterYMS</b> to rozwiązanie klasy „Yard Management System” umożliwiające zarządzanie
                        awizacjami i automatyzację procesów realizowanymi na terenie centrów logistycznych przez
                        pracowników portierni i magazynu oraz obsługiwanych przez nich kierowców.
                    </article>
                    <!-- <article class="mb-20">
                        <b>SmarterYMS</b>
                        <ul class="deliveryProcessList">
                            <li class="mb-10">
                                Umożliwia zarządzanie w czasie rzeczywistym procesami logistycznymi na terenie centrum
                                logistycznego.
                            </li>
                            <li class="mb-10">
                                Wizualizuje w czasie rzeczywistym ile samochodów jest w trakcie załadunku / rozładunku,
                                ile oczekuje na
                                placu, ile jest na pauzie.
                            </li>
                            <li class="mb-10">
                                Monitoruje, lokalizuje i zarządza pojazdami na terenie centrum dystrybucji, na bramach,
                                parkingach i
                                placach manewrowych.
                            </li>
                            <li class="mb-10">
                                Automatyzuje i upraszcza komunikację pomiędzy magazynem, portiernią i kierowcą.
                            </li>
                            <li class="mb-10">
                                Standaryzuje procesy związane z obsługa pojazdów na terenie centrum logistycznego w
                                kontekście
                                egzekwowania procedur bezpieczeństwa.
                            </li>
                            <li class="mb-10">
                                Dostarcza informacje w czasie rzeczywistym o pojazdach oczekujących na załadunek,
                                planowo oraz tych
                                będących wcześniej.
                            </li>
                            <li class="mb-10">
                                Automatycznie ostrzega o możliwości spóźnień pojazdów, i weryfikuje plan okien
                                awizacyjnych (przy
                                integracji ze SmarterPOD).
                            </li>
                            <li class="mb-10">
                                Automatyzuje weryfikację pojazdów i kierowców do awizacji (SmarterGATE).
                            </li>
                        </ul>
                    </article> -->
                </section>
                <!-- YMS cd -->
                <!-- <section class="sectionImage mb-20" id="podMiddImgb"></section> -->
                <ul class="logicList mb-20 mtbD-30">
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon19.svg"></div>
                        </div>
                        <div class="text">
                            <b>WEBbooking</b>
                            WEBbooking umożliwia preawizację wizyt przez dostawców.
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon20.svg"></div>
                        </div>
                        <div class="text"><b>Planowanie awizacji</b>
                            Planowanie manualne oraz import danych, integracja z systemami WMS / TMS / ERP.
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon22.svg"></div>
                        </div>
                        <div class="text">
                            <b>Automatyzacja</b>
                            Automatyzacja identyfikacji i awizacji kierowców oraz pojazdów w portierni dzięki
                            rozwiązaniu SmarterGATE.
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon21.svg"></div>
                        </div>
                        <div class="text">
                            <b>Zarządzanie pojazdami</b>
                            Zarządzanie sekwencją podstawiania pojazdów pod rampy, zarządzanie parkingami i placami
                            manewrowymi.
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon5.svg"></div>
                        </div>
                        <div class="text">
                            <b>Planowanie rozładunków i załadunków</b>
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon7.svg"></div>
                        </div>
                        <div class="text">
                            <b>Obsługa multi-rozładunków i multi-załadunków</b>
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon23.svg"></div>
                        </div>
                        <div class="text">
                            <b>Automatyczne powiadomienia</b>
                            Automatyczne powiadomienia dotyczące opóźnień i przestojów na terenie parku logistycznego.
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon25.svg"></div>
                        </div>
                        <div class="text">
                            <b>Ostrzeżenia</b>
                            Ostrzeżenia o możliwości spóźnieniach dojazdu do parku logistycznego pojazdów zaplanowanych
                            do obsługi
                            wpływających na okna awizacyjne na obiekcie (przy integracji z POD).
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon8.svg"></div>
                        </div>
                        <div class="text">
                            <b>Monitorowanie</b>
                            Monitorowanie i lokalizowanie pojazdów na terenie parku logistycznego.
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon26.svg"></div>
                        </div>
                        <div class="text">
                            <b>Zarządzanie harmonogramem</b>
                            Zarządzanie harmonogramem obsługi ramp logistycznych.
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon27.svg"></div>
                        </div>
                        <div class="text">
                            <b>Indywidualne zarządzanie</b>
                            Definiowanie indywidualnych reguł obsługi dla typów klientów / transportu / produktu.
                        </div>
                    </li>
                </ul>

                <section>
                    <!-- Kluczowe procesy dostawy -->
                    <article class="mb-20 mtbD-30">
                        <b>SmarterYMS</b> wspiera różne obszary biznesowe w organizacji:
                    </article>
                    <div class="postArea mb-20 mtbD-30">
                        <div class="postButtons">
                            <div class="postButtonsItem">
                                <div>Portiernia</div>
                            </div>
                            <div class="descItem mt-20">
                                <ul class="deliveryProcessList">
                                    <li class="mb-10">
                                        Bezbłędna automatyczna awizacja kierowców i pojazdów.
                                    </li>
                                    <li class="mb-10">
                                        Monitoring w czasie rzeczywistym stanu wszystkich bram, totemów, infokiosków,
                                        zainstalowanych na
                                        platformie (SmarterGATE).
                                    </li>
                                    <li class="mb-10">
                                        Aktywny spis wizyt planowanych do obsługi, w trakcie obsługi i obsłużonych.
                                    </li>
                                    <li class="mb-10">
                                        Weryfikacja prawidłowości zrealizowanych o planowanych załadunków na bramie
                                        czytnikami MIFARE i
                                        RFID.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="postButtons">
                            <div class="postButtonsItem">
                                <div>Magazyn</div>
                            </div>
                            <div class="descItem mt-20">
                                <ul class="deliveryProcessList">
                                    <li class="mb-10">
                                        Planowanie „capacity” awizacji w systemie wraz z kontrolą limitów przyjęć,
                                        zaczytywanie planów i
                                        integracja z systemami WMS, ERP.
                                    </li>
                                    <li class="mb-10">
                                        Monitorowanie i zarządzanie w czasie rzeczywistym awizacjami, wraz z czytelną
                                        wizualizacją statusu
                                        realizacji dostępną dla wskazanych użytkowników końcowych.
                                    </li>
                                    <li class="mb-10">
                                        Monitorowanie i zarządzanie w czasie rzeczywistym operacjami na rampach,
                                        przypisywanie ramp do
                                        celów, zgłaszanie niedyspozycyjności rampy przez użytkowników.
                                    </li>
                                    <li class="mb-10">
                                        Automatyzacja komunikacji poleceń dla kierowców, wezwania SMS / Pager.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="postButtons">
                            <div class="postButtonsItem">
                                <div>Transport</div>
                            </div>
                            <div class="descItem mt-20">
                                <ul class="deliveryProcessList">
                                    <li class="mb-10">
                                        Planowanie awizacji klientów w systemie wraz z kontrolą limitów przyjęć,
                                        zaczytywanie planów i
                                        integracja z systemami WMS, ERP.
                                    </li>
                                    <li class="mb-10">
                                        Bieżące raportowanie realizacji awizacji.
                                    </li>
                                    <li class="mb-10">
                                        Raportowanie nieprawidłowości, np. opóźnień, wraz z przyczynami ich wystąpienia.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="postButtons">
                            <div class="postButtonsItem">
                                <div>Kierowcy</div>
                            </div>
                            <div class="descItem mt-20">
                                <ul class="deliveryProcessList">
                                    <li class="mb-10">
                                        Automatyzacja procesów i wsparcie przy identyfikacji kierowców, pojazdów, oraz
                                        rejestracji
                                        dokumentacji przewozowej. (AutomaticGATE).
                                    </li>
                                    <li class="mb-10">
                                        Automatyczne kolejkowanie kierowców do danego zadania, rampy.
                                    </li>
                                    <li class="mb-10">
                                        Dedykowane komunikaty i statusy dla kierowcy do danego procesu.
                                    </li>
                                    <li class="mb-10">
                                        Zdalna rejestracja i aktualizacja planowanej wizyty w centrum logistycznym
                                        (WEBooking).
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="postButtons">
                            <div class="postButtonsItem">
                                <div>Raportowanie </div>
                            </div>
                            <div class="descItem mt-20">
                                <ul class="deliveryProcessList">
                                    <li class="mb-10">
                                        Budowa spersonalizowanych raportów w zakresie typu i obszaru danych wraz z
                                        definicją cykliczności
                                        raportów.
                                    </li>
                                    <li class="mb-10">
                                        Definicja własnych wskaźników KPI i statystyk pomiaru efektywności realizacji
                                        dostaw.
                                    </li>
                                    <li class="mb-10">
                                        Personalizowane dashboardy.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <article class="mb-20 mtbD-30">
                        <b>SmarterYMS</b> to rozwiązanie dla firm logistycznych, firm produkcyjnych
                        i dystrybutorów.
                    </article>
                    <div class="mb-20 mtbD-30">
                        <ul class="logicList">
                            <li class="gridLine">
                                <div class="iconBox2">
                                    <div class="icon"><img src="assets/icon9.svg"></div>
                                </div>
                                <div class="text">
                                    <b>Operatorzy logistyczni</b>
                                    Nasze rozwiązanie kierujemy do dużych firm logistycznych, posiadających kilka
                                    platform załadunkowych. Obecnie SmarterYMS jest wykorzystywany przez operatorów
                                    logistycznych w kilku krajach UE.
                                </div>
                            </li>
                            <li class="gridLine">
                                <div class="iconBox2">
                                    <div class="icon"><img src="assets/icon10.svg"></div>
                                </div>
                                <div class="text">
                                    <b>Producenci i dystrybutorzy</b>
                                    SmarterYMS jest także stosowany do zarządzania operacjami na terenach centrów
                                    produkcyjnych i dystrybucyjnych.
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
            </section>
            <!-- Stopka -->
            <section class="footertop px-15">
                <div class="desctopContent">
                    <div class="mb-20 mt-10">
                        <b style="font-size: 18px;">Współpracuj z nami</b>
                        <br><br>
                        Daj nam znać jak możemy pomóc twojej firmie. Odpowiemy niezwłocznie.
                    </div>
                    <div class="mb-20">
                        <button class="contactBtn accentBg" (click)="contactUs()">
                            Skontaktuj się z nami
                        </button>
                    </div>
                </div>
            </section>
            <!-- podziel się -->
            <section class="footerMiddle pt-10 px-15">
                <div class="desctopContent">
                    <div class="flex flexItemCenter mb-10">
                        <div class="icon"><img src="assets/m1.svg"></div>
                        <div class="ml-20">Pobierz PDF</div>
                    </div>
                    <div class="flex flexItemCenter mb-10">
                        <div class="icon"><img src="assets/m2.svg"></div>
                        <div class="ml-20">Prześlij znajomemu</div>
                    </div>
                    <div class="flex flexItemCenter backButton">
                        <div class="icon"><img src="assets/m3.svg"></div>
                        <div class="ml-20" (click)="fullpage_api.moveTo('yms', 0);">Powrót</div>
                    </div>
                </div>
            </section>
            <app-footer></app-footer>
        </div>
    </div>
    <!-- Operatorzy logistyczni -->
    <div class="section coverImg" id="screen4">
        <div class="slide">
            <div class="topWrapper">
                <div id="logoMenu" class="flex flexItemCenter saLogo px-15 pxD-35 ">
                    <div class="logoRotate2 mr-5">
                        <img src="assets/logo_icon.svg">
                    </div>
                    <div class="logoText">
                        <img src="assets/sa_text2.svg">
                    </div>
                </div>
                <div id="burgerMenu" class="flexRight px-15 pxD-35 ">
                    <div class="burger" (click)="openMenu()">
                        <div class="line1"></div>
                        <div class="short line2"></div>
                        <div class="line3"></div>
                    </div>
                </div>
            </div>
            <div class="gridEnd desktopHeaderLineBox ">
                <div class="px-15 desktopPx-35">
                    <small class="hero">Operatorzy logistyczni</small>
                    <h3 class="mt-10 mb-20"><span>D</span>ostarczamy<br> systemy do integracji<br>
                        łańcucha dostaw</h3>
                    <section class="moreArrow" (click)="fullpage_api.moveTo('operatorzy-logistyczni', 1);">
                        Umożliwiamy zarządzanie dostawami w czasie rzeczywistym, automatyzację operacji i komunikacji w
                        centrach
                        logistycznych i integrację z systemami klasy TMS, WMS i ERP.
                        <span class="arrowButton"><img src="assets/arrowN.svg"></span>
                    </section>
                </div>
            </div>
        </div>
        <!-- Szablon Operatorzy logistyczni -->
        <div class="slide slidePanel">
            <!-- Nagłówek & menu -->
            <section class="gridWraperLayout desctopContent mb-10 px-15">
                <div class="logo2">
                    <img src="assets/logo_ba.svg">
                </div>
                <div class="burger2" (click)="fullpage_api.moveTo('operatorzy-logistyczni', 0);">
                    <div class="line1"></div>
                    <div class="short line2"></div>
                    <div class="line3"></div>
                </div>
            </section>
            <section class="sectionImage mb-20" id="podTopImg4"></section>
            <section class="desctopContent px-15">

                <!-- Wstęp -->
                <section class="headerLine mb-20">
                    <div class="iconImg"></div>
                    <h3>Operatorzy logistyczni</h3>
                </section>
                <section>
                    <article class="mb-20 mtbD-30">
                        Dostarczamy systemy informatyczne do zarządzania logistyką mobilną dla operatorów logistycznych.
                        SmarterPOD oraz SmarterYMS przechwytują dane powstające podczas realizacji dostaw, załadunków,
                        przeładunków i rozładunków w <b>miejscu</b> i <b>w czasie</b> ich powstawania. Eliminujemy
                        zarządzanie analogowe w oparciu o dokumenty papierowe i przenosimy procesy do
                        systemów cyfrowych. Znacząco poprawiamy jakość danych, którymi zasilane są systemy WMS, TMS,
                        ERP, integrując je ze
                        sobą oraz umożliwiając realną optymalizację procesów i efektywniejsze planowanie. W efekcie
                        sprowadzenia wielu, różnych typów działalności operacyjnej logistyki mobilnej „do
                        jednego mianownika” możliwe jest:
                    </article>
                </section>
                <div class="mb-20 mtbD-30">
                    <ul class="logicList">
                        <li class="gridLine">
                            <div class="iconBox2">
                                <div class="icon"><img src="assets/icon28.svg"></div>
                            </div>
                            <div class="text">
                                Skrócenie „time to money”.
                            </div>
                        </li>
                        <li class="gridLine">
                            <div class="iconBox2">
                                <div class="icon"><img src="assets/icon29.svg"></div>
                            </div>
                            <div class="text">
                                Skrócenie czasu operacji.
                            </div>
                        </li>
                        <li class="gridLine">
                            <div class="iconBox2">
                                <div class="icon"><img src="assets/icon30.svg"></div>
                            </div>
                            <div class="text">
                                Zwiększenie wydajności zasobów.
                            </div>
                        </li>
                        <li class="gridLine">
                            <div class="iconBox2">
                                <div class="icon"><img src="assets/icon31.svg"></div>
                            </div>
                            <div class="text">
                                Zmniejszenie kosztów jednostkowych.
                            </div>
                        </li>
                        <li class="gridLine">
                            <div class="iconBox2">
                                <div class="icon"><img src="assets/icon32.svg"></div>
                            </div>
                            <div class="text">
                                Poprawienie transparentności operacyjnej.
                            </div>
                        </li>
                    </ul>
                </div>
                <section>
                    <article class="mb-20 mtbD-30">
                        Znacząco zwiększamy możliwości realizacji logistyki dla odbiorców eCommerce, zarówno B2B jak i
                        B2C.
                    </article>
                </section>
                <!-- Rozwiązania dla dużych i małych firm -->
                <article class="mb-20 mtbD-30">
                    <b>Wspieramy wiele typów aktywności logistycznej:</b>
                </article>
                <!-- Wspierane branże -->
                <section class="gridBox3Columns mb-20 mtbD-30">
                    <div class="flip-card" [ngClass]="{'showBack' : showBack}" (click)="showBack = !showBack">
                        <div class="flip-card-inner">
                            <div class="flip-card-front">
                                <div class="borderBox">
                                    <div class="icon mb-5"><img src="assets/icon001.svg"></div>
                                    <div class="text">
                                        Fresh
                                    </div>
                                </div>
                            </div>
                            <div class="flip-card-back">
                                <div class="borderBox">
                                    Monitorowanie temperatury, wilgotności przewożonego ładunku w czasie rzeczywistym,
                                    reklamacje towarowe
                                    i paletowe. Płatności mobilne.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flip-card" [ngClass]="{'showBack' : showBack2}" (click)="showBack2 = !showBack2">
                        <div class="flip-card-inner">
                            <div class="flip-card-front">
                                <div class="borderBox">
                                    <div class="icon mb-5"><img src="assets/icon002.svg"></div>
                                    <div class="text">
                                        Food & Beverages
                                    </div>
                                </div>
                            </div>
                            <div class="flip-card-back">
                                <div class="borderBox">
                                    Monitorowanie temperatury w czasie rzeczywistym, zarządzanie opakowaniami zwrotnymi,
                                    reklamacje
                                    towarowe i paletowe. Płatności mobilne. Usługi dodatkowe.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flip-card" [ngClass]="{'showBack' : showBack3}" (click)="showBack3 = !showBack3">
                        <div class="flip-card-inner">
                            <div class="flip-card-front">
                                <div class="borderBox">
                                    <div class="icon mb-5"><img src="assets/icon003.svg"></div>
                                    <div class="text">
                                        FMCG
                                    </div>
                                </div>
                            </div>
                            <div class="flip-card-back">
                                <div class="borderBox">
                                    Monitorowanie przewożonego ładunku w czasie rzeczywistym, reklamacje towarowe i
                                    paletowe. Płatności
                                    mobilne. Usługi dodatkowe.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flip-card" [ngClass]="{'showBack' : showBack4}" (click)="showBack4 = !showBack4">
                        <div class="flip-card-inner">
                            <div class="flip-card-front">
                                <div class="borderBox">
                                    <div class="icon mb-5"><img src="assets/icon004.svg"></div>
                                    <div class="text">
                                        Pharmaceutics & Med-Labs
                                    </div>
                                </div>
                            </div>
                            <div class="flip-card-back">
                                <div class="borderBox">
                                    Monitorowanie temperatury, wilgotności przewożonego ładunku w czasie rzeczywistym,
                                    zarządzanie
                                    opakowaniami zwrotnymi, reklamacje towarowe. Płatności mobilne.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flip-card" [ngClass]="{'showBack' : showBack5}" (click)="showBack5 = !showBack5">
                        <div class="flip-card-inner">
                            <div class="flip-card-front">
                                <div class="borderBox">
                                    <div class="icon mb-5"><img src="assets/icon008.svg"></div>
                                    <div class="text">
                                        Building Materials
                                    </div>
                                </div>
                            </div>
                            <div class="flip-card-back">
                                <div class="borderBox">
                                    Monitorowanie przewożonego ładunku w czasie rzeczywistym, reklamacje towarowe i
                                    paletowe. Usługi
                                    dodatkowe. Płatności mobilne.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flip-card" [ngClass]="{'showBack' : showBack6}" (click)="showBack6= !showBack6">
                        <div class="flip-card-inner">
                            <div class="flip-card-front">
                                <div class="borderBox">
                                    <div class="icon mb-5"><img src="assets/icon006.svg"></div>
                                    <div class="text">
                                        Electronics, Furnishing, Home Appliance.
                                    </div>
                                </div>
                            </div>
                            <div class="flip-card-back">
                                <div class="borderBox">
                                    Monitorowanie przewożonego ładunku w czasie rzeczywistym, reklamacje towarowe i
                                    paletowe. Usługi
                                    dodatkowe. Płatności mobilne.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flip-card" [ngClass]="{'showBack' : showBack7}" (click)="showBack7 = !showBack7">
                        <div class="flip-card-inner">
                            <div class="flip-card-front">
                                <div class="borderBox">
                                    <div class="icon mb-5"><img src="assets/icon007.svg"></div>
                                    <div class="text">
                                        B2B Distribution, Automotive
                                    </div>
                                </div>
                            </div>
                            <div class="flip-card-back">
                                <div class="borderBox">
                                    Monitorowanie przewożonego ładunku w czasie rzeczywistym, reklamacje towarowe i
                                    paletowe. Usługi
                                    dodatkowe. Płatności mobilne.
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            <!-- Stopka -->
            <section class="footertop px-15">
                <div class="desctopContent">
                    <div class="mb-20 mt-10">
                        <b style="font-size: 18px;">Współpracuj z nami</b>
                        <br><br>
                        Daj nam znać jak możemy pomóc twojej firmie. Odpowiemy niezwłocznie.
                    </div>
                    <div class="mb-20">
                        <button class="contactBtn accentBg" (click)="contactUs()">
                            Skontaktuj się z nami
                        </button>
                    </div>
                </div>
            </section>
            <!-- podziel się -->
            <section class="footerMiddle pt-10 px-15">
                <div class="desctopContent">
                    <div class="flex flexItemCenter mb-10">
                        <div class="icon"><img src="assets/m1.svg"></div>
                        <div class="ml-20">Pobierz PDF</div>
                    </div>
                    <div class="flex flexItemCenter mb-10">
                        <div class="icon"><img src="assets/m2.svg"></div>
                        <div class="ml-20">Prześlij znajomemu</div>
                    </div>
                    <div class="flex flexItemCenter backButton">
                        <div class="icon"><img src="assets/m3.svg"></div>
                        <div class="ml-20" (click)="fullpage_api.moveTo('operatorzy-logistyczni', 0);">Powrót</div>
                    </div>
                </div>
            </section>
            <app-footer></app-footer>
        </div>
    </div>
    <!-- Producenci i dystrybutorzy -->
    <div class="section coverImg" id="screen5">
        <div class="slide">
            <div class="topWrapper">
                <div id="logoMenu" class="flex flexItemCenter saLogo px-15 pxD-35 ">
                    <div class="logoRotate2 mr-5">
                        <img src="assets/logo_icon.svg">
                    </div>
                    <div class="logoText">
                        <img src="assets/sa_text2.svg">
                    </div>
                </div>
                <div id="burgerMenu" class="flexRight px-15 pxD-35 ">
                    <div class="burger" (click)="openMenu()">
                        <div class="line1"></div>
                        <div class="short line2"></div>
                        <div class="line3"></div>
                    </div>
                </div>
            </div>
            <div class="gridEnd desktopHeaderLineBox ">
                <div class="px-15 desktopPx-35">
                    <small class="hero">Producenci i dystrybutorzy</small>
                    <h3 class="mt-10 mb-20"><span>W</span>drażamy<br> najlepsze
                        praktyki<br>
                        liderów rynku <span>TSL</span></h3>
                    <section class="moreArrow" (click)="fullpage_api.moveTo('producenci-i-dystrybutorzy', 1);">
                        Dostarczamy systemy do zarządzania logistyką produkcji w czasie rzeczywistym.
                        <span class="arrowButton"><img src="assets/arrowN.svg"></span>
                    </section>
                </div>
                <div class="shortStory halfGrid">
                    <div class="textBlock">
                        <div class="icon">
                            <img src="assets/icon45.svg">
                        </div>
                        <div class="textContent">
                            <div class="header">
                                Transformacja cyfrowa zarzadzania logistyką
                            </div>
                            <div class="content">
                                Wyposażamy firmy realizujące logistykę samodzielnie w najlepsze praktyki logistyki
                                sektorowej, stosowane
                                przez liderów rynku TSL w obszarach technologii, definicji i standaryzacji procesów,
                                zarządzania
                                operacjami.
                            </div>
                        </div>
                    </div>
                    <div class="textBlock">
                        <div class="icon">
                            <img src="assets/icon46.svg">
                        </div>
                        <div class="textContent">
                            <div class="header">
                                Optymalizacja i Transparentność.
                            </div>
                            <div class="content">
                                Znacząco zwiększamy wydajność zasobów parków magazynowych i transportu, obniżamy koszty
                                jednostkowe
                                logistyki. Wizualizujemy w czasie rzeczywistym statusy realizacji zadań, zapobiegamy
                                nieprawidłowościom i
                                opóźnieniom, proaktywnie informując dyspozytorów i kierowców o potencjalnych problemach
                                i
                                możliwościach
                                przeciwdziałania im.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Szablon Producenci i dystrybutorzy -->
        <div class="slide slidePanel">
            <!-- Nagłówek & menu -->
            <section class="gridWraperLayout desctopContent px-15 mb-10">
                <div class="logo2">
                    <img src="assets/logo_ba.svg">
                </div>
                <div class="burger2" (click)="fullpage_api.moveTo('producenci-i-dystrybutorzy', 0);">
                    <div class="line1"></div>
                    <div class="short line2"></div>
                    <div class="line3"></div>
                </div>
            </section>
            <section class="sectionImage mb-20" id="podTopImg5"></section>
            <section class="desctopContent px-15">
                <!-- Wstęp -->
                <section class="headerLine mb-20">
                    <div class="iconImg"></div>
                    <h3>Producenci i dystrybutorzy</h3>
                </section>
                <section>
                    <article class="mb-20 mtbD-30">
                        <b>SmarterApps</b> wspiera Raportowanie logistycznymi w firmach realizujących
                        logistykę
                        samodzielnie.
                    </article>
                    <article class="mb-20 mtbD-30">
                        Digitalizujemy zarządzanie logistyką produkcji i dystrybucji:
                    </article>
                </section>
                <!-- Rozwiązania dla dużych i małych firm -->
                <ul class="logicList mb-20 mtbD-30">
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon33.svg"></div>
                        </div>
                        <div class="text">
                            Standaryzujemy, optymalizujemy i automatyzujemy procesy.
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon34.svg"></div>
                        </div>
                        <div class="text">
                            Ułatwiamy optymalne planowanie operacji dostaw.
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon35.svg"></div>
                        </div>
                        <div class="text">
                            Wspieramy kierowców w realizacji zadań, prowadząc ich zgodnie z ustalonym procesem.
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon26.svg"></div>
                        </div>
                        <div class="text">
                            Wspieramy pracowników magazynów, obsługę ramp, portierów w optymalnej realizacji procesów
                            przy załadunku i
                            rozładunku, na parkingach i placach manewrowych oraz podczas awizacji kierowców.
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon19.svg"></div>
                        </div>
                        <div class="text">
                            Umożliwiamy dyspozytorom monitorowanie realizowanych zadań w czasie rzeczywistym i
                            natychmiastową reakcję
                            na wypadek niepożądanych zdarzeń.
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon25.svg"></div>
                        </div>
                        <div class="text">
                            Zapobiegamy nieprawidłowościom i opóźnieniom proaktywnie informując dyspozytorów o
                            potencjalnych
                            zagrożeniach i możliwościach przeciwdziałania im.
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon16.svg"></div>
                        </div>
                        <div class="text">
                            Raportujemy zarządzającym logistyką, na bieżąco, cyklicznie i ad-hoc, realizację planów,
                            KPI,
                            nieprawidłowości i ich przyczyny.
                        </div>
                    </li>
                    <li class="gridLine">
                        <div class="iconBox">
                            <div class="icon"><img src="assets/icon17.svg"></div>
                        </div>
                        <div class="text">
                            Dajemy narządzie pozwalające na informowanie w czasie rzeczywistym klientów i odbiorców o
                            statusie
                            realizowanych dla nich dostaw.
                        </div>
                    </li>
                </ul>
                <article class="mb-20 mtbD-30 heroText">
                    <b>Podnosimy jakość i wydajność operacji. Obniżamy koszty logistyki.</b>
                </article>
            </section>
            <!-- Stopka -->
            <section class="footertop px-15">
                <div class="desctopContent">
                    <div class="mb-20 mt-10">
                        <b style="font-size: 18px;">Współpracuj z nami</b>
                        <br><br>
                        Daj nam znać jak możemy pomóc twojej firmie. Odpowiemy niezwłocznie.
                    </div>
                    <div class="mb-20">
                        <button class="contactBtn accentBg" (click)="contactUs()">
                            Skontaktuj się z nami
                        </button>
                    </div>
                </div>
            </section>
            <!-- podziel się -->
            <section class="footerMiddle pt-10 px-15">
                <div class="desctopContent">
                    <div class="flex flexItemCenter mb-10">
                        <div class="icon"><img src="assets/m1.svg"></div>
                        <div class="ml-20">Pobierz PDF</div>
                    </div>
                    <div class="flex flexItemCenter mb-10">
                        <div class="icon"><img src="assets/m2.svg"></div>
                        <div class="ml-20">Prześlij znajomemu</div>
                    </div>
                    <div class="flex flexItemCenter backButton">
                        <div class="icon"><img src="assets/m3.svg"></div>
                        <div class="ml-20" (click)="fullpage_api.moveTo('producenci-i-dystrybutorzy', 0);">Powrót</div>
                    </div>
                </div>
            </section>
            <app-footer></app-footer>
        </div>
    </div>
    <!-- Firmy Transportowe -->
    <div class="section coverImg" id="screen6">
        <div class="slide">
            <div class="topWrapper">
                <div id="logoMenu" class="flex flexItemCenter saLogo px-15 pxD-35 ">
                    <div class="logoRotate2 mr-5">
                        <img src="assets/logo_icon.svg">
                    </div>
                    <div class="logoText">
                        <img src="assets/sa_text2.svg">
                    </div>
                </div>
                <div id="burgerMenu" class="flexRight px-15 pxD-35 ">
                    <div class="burger" (click)="openMenu()">
                        <div class="line1"></div>
                        <div class="short line2"></div>
                        <div class="line3"></div>
                    </div>
                </div>
            </div>
            <div class="gridEnd desktopHeaderLineBox ">
                <div class="px-15 desktopPx-35">
                    <small class="hero">Firmy Transportowe</small>
                    <h3 class="mt-10 mb-20"><span>P</span>ełna<br> kontrola nad<br>
                        realizacją zleceń
                    </h3>
                    <section class="moreArrow" (click)="fullpage_api.moveTo('firmy-transportowe', 1);">
                        Pełna kontrola w czasie rzeczywistym nad kierowcami, zadaniami i kosztami i proaktywne
                        zapobieganie
                        opóźnieniom.
                        <span class="arrowButton"><img src="assets/arrowN.svg"></span>
                    </section>
                </div>
            </div>
        </div>
        <!-- Szablon Firmy Transportowe -->
        <div class="slide slidePanel">
            <!-- Nagłówek & menu -->
            <section class="gridWraperLayout desctopContent mb-10 px-15">
                <div class="logo2">
                    <img src="assets/logo_ba.svg">
                </div>
                <div class="burger2" (click)="fullpage_api.moveTo('firmy-transportowe', 0);">
                    <div class="line1"></div>
                    <div class="short line2"></div>
                    <div class="line3"></div>
                </div>
            </section>
            <section class="sectionImage mb-20" id="podTopImg6"></section>
            <section class="desctopContent px-15">
                <!-- Wstęp -->
                <section class="headerLine mb-20">
                    <div class="iconImg"></div>
                    <h3>Transport</h3>
                </section>
                <section>
                    <article class="mb-20 mtbD-30">
                        <b>SmarterPOD<span class="redColor italic">lite</span></b> to narzędzie wspierające osoby
                        zarządzające
                        firmami transportowymi oraz kierowców w trakcie realizacji dostaw.
                    </article>
                    <article class="mb-20 mtbD-30">
                        Główne zalety naszego rozwiązania:
                    </article>
                    <ul class="logicList mb-20 mtbD-30">
                        <li class="gridLine">
                            <div class="iconBox">
                                <div class="icon"><img src="assets/icon36.svg"></div>
                            </div>
                            <div class="text">
                                Proste planowanie tras i zadań na mapie z funkcjonalnością „drag and drop”.
                            </div>
                        </li>
                        <li class="gridLine">
                            <div class="iconBox">
                                <div class="icon"><img src="assets/icon38.svg"></div>
                            </div>
                            <div class="text">
                                Integracja z telematyką, tacho, eco-driving.
                            </div>
                        </li>
                        <li class="gridLine">
                            <div class="iconBox">
                                <div class="icon"><img src="assets/icon39.svg"></div>
                            </div>
                            <div class="text">
                                Moduł optymalizowania trasy i zadań pod kątem najkrótszego czasu realizacji, dostawy
                                „just in time”, i
                                przy innych warunkach, które sam definiujesz.
                            </div>
                        </li>
                        <li class="gridLine">
                            <div class="iconBox">
                                <div class="icon"><img src="assets/icon40.svg"></div>
                            </div>
                            <div class="text">
                                Nawigacja kierowców, monitoring i lokalizacja w czasie rzeczywistym pojazdu, przesyłek i
                                kierowcy.
                            </div>
                        </li>
                        <li class="gridLine">
                            <div class="iconBox">
                                <div class="icon"><img src="assets/icon25.svg"></div>
                            </div>
                            <div class="text">
                                Powiadomienia i alerty przeciwdziałające opóźnieniom, wysyłane przez system do
                                kierowców, oraz przez
                                kierowców do innych kierowców i do bazy.
                            </div>
                        </li>
                        <li class="gridLine">
                            <div class="iconBox">
                                <div class="icon"><img src="assets/icon41.svg"></div>
                            </div>
                            <div class="text">
                                Zapobieganie reklamacjom i zarządzanie problemem w czasie rzeczywistym poprzez
                                komunikację w aplikacji
                                ze wszystkimi uczestnikami procesu, tj. wysyłającym, odbiorcą, zlecającym transport,
                                kierowcą i
                                dyspozytorem w firmie transportowej.
                            </div>
                        </li>
                        <li class="gridLine">
                            <div class="iconBox">
                                <div class="icon"><img src="assets/icon42.svg"></div>
                            </div>
                            <div class="text">
                                Podpis klienta przy odbiorze na telefonie lub zdjęcie dokumentów papierowych wraz z
                                modułem akceptacji
                                poprawności wykonanych zdjęć.
                            </div>
                        </li>
                        <li class="gridLine">
                            <div class="iconBox">
                                <div class="icon"><img src="assets/icon43.svg"></div>
                            </div>
                            <div class="text">
                                Możliwa automatyzacja wystawiania faktur po zrealizowanych zleceniach.
                            </div>
                        </li>
                        <li class="gridLine">
                            <div class="iconBox">
                                <div class="icon"><img src="assets/icon44.svg"></div>
                            </div>
                            <div class="text">
                                Oprogramowanie łatwe do pobrania i intuicyjne w samodzielnej konfiguracji.
                            </div>
                        </li>
                    </ul>
                    <article class="mb-20 mtbD-30">
                        <div class="mb-20 mtbD-30">
                            Już niedługo dostępne na:
                        </div>
                        <img class="mr-5" width="130" src="assets/gPlay.svg">
                        <img width="130" src="assets/aStore.svg">
                    </article>
                </section>
            </section>
            <!-- Stopka -->
            <section class="footertop px-15">
                <div class="desctopContent">
                    <div class="mb-20 mt-10">
                        <b style="font-size: 18px;">Współpracuj z nami</b>
                        <br><br>
                        Daj nam znać jak możemy pomóc twojej firmie. Odpowiemy niezwłocznie.
                    </div>
                    <div class="mb-20">
                        <button class="contactBtn accentBg" (click)="contactUs()">
                            Skontaktuj się z nami
                        </button>
                    </div>
                </div>
            </section>
            <!-- podziel się -->
            <section class="footerMiddle pt-10 px-15">
                <div class="desctopContent">
                    <div class="flex flexItemCenter mb-10">
                        <div class="icon"><img src="assets/m1.svg"></div>
                        <div class="ml-20">Pobierz PDF</div>
                    </div>
                    <div class="flex flexItemCenter mb-10">
                        <div class="icon"><img src="assets/m2.svg"></div>
                        <div class="ml-20">Prześlij znajomemu</div>
                    </div>
                    <div class="flex flexItemCenter backButton">
                        <div class="icon"><img src="assets/m3.svg"></div>
                        <div class="ml-20" (click)="fullpage_api.moveTo('firmy-transportowe', 0);">Powrót</div>
                    </div>
                </div>
            </section>
            <app-footer></app-footer>
        </div>
    </div>
    <!-- O Firmie -->
    <div class="section coverImg" id="screen7">
        <div class="slide">
            <div class="gridEnd desktopHeaderLineBox ">
                <div class="topWrapper">
                    <div id="logoMenu" class="flex flexItemCenter saLogo px-15 pxD-35 ">
                        <div class="logoRotate2 mr-5">
                            <img src="assets/logo_icon.svg">
                        </div>
                        <div class="logoText">
                            <img src="assets/sa_text2.svg">
                        </div>
                    </div>
                    <div id="burgerMenu" class="flexRight px-15 pxD-35 ">
                        <div class="burger" (click)="openMenu()">
                            <div class="line1"></div>
                            <div class="short line2"></div>
                            <div class="line3"></div>
                        </div>
                    </div>
                </div>
                <div class="px-15 desktopPx-35">
                    <small class="hero">O Firmie</small>
                    <h3 class="mt-10 mb-20"><span>R</span>ozumiemy<br> logistykę i
                        <br>technologię
                    </h3>
                    <section class="moreArrow" (click)="fullpage_api.moveTo('o-firmie', 1);">
                        Tworzymy sprytne i pragmatyczne innowacje, które optymalizują logistykę mobilną.
                        <span class="arrowButton"><img src="assets/arrowN.svg"></span>
                    </section>
                </div>
            </div>
        </div>
        <!-- Szablon O Firmie -->
        <div class="slide slidePanel">
            <!-- Nagłówek & menu -->
            <section class="gridWraperLayout desctopContent mb-10 px-15">
                <div class="logo2">
                    <img src="assets/logo_ba.svg">
                </div>
                <div class="burger2" (click)="fullpage_api.moveTo('o-firmie', 0);">
                    <div class="line1"></div>
                    <div class="short line2"></div>
                    <div class="line3"></div>
                </div>
            </section>
            <section class="sectionImage mb-20" id="podTopImg7"></section>
            <section class="desctopContent px-15">
                <!-- Wstęp -->
                <section class="headerLine mb-20">
                    <div class="iconImg"></div>
                    <h3>O Firmie</h3>
                </section>
                <section>
                    <article class="mb-20 mtbD-30">
                        <h2>Smarter Philosophy</h2>
                        Rozumiemy logistykę i technologię. <br><br>
                        Wykorzystujemy tę wiedzę do tworzenia pragmatycznych innowacji, które pozwalają na optymalizację
                        logistyki mobilnej. Pierwszej mila, operacje w centrach logistycznych i ostatnia mila to
                        najdroższe i najtrudniejsze w efektywnym zarządzaniu części łańcucha dostaw. Eliminujemy
                        przyczyny i skutki nieefektywności pracy na wielu niezintegrowanych systemach oraz dokumentach
                        papierowych, których obsługa jest czasochłonna, frustrująca i podatna na błędy. Digitalizujemy,
                        standaryzujemy, automatyzujemy i optymalizujemy procesy logistyczne, w efekcie
                        czego, użytkownicy naszych systemów, otrzymują narzędzia zdecydowanie usprawniające ich pracę.
                        <br><br>
                        Dostarczamy sprytniejsze rozwiązania i dlatego nazywamy się SmarterApps.
                    </article>
                    <!-- Procesy logistyczne -->
                    <!-- <article class="mb-20 mtbD-30">
                        <h2>Referencje</h2>
                        Jesteśmy dostawcą rozwiązań dla T-Systems, oferującego produkty SmarterApps w modelu whitelabel
                        do swoich
                        klientów w ponad 50 krajach. <br><br>
                        Portfolio naszych klientów to głównie duże firmy z sektora logistyki, produkcji i dystrybucji.
                        <br><br>
                        Nasze systemy są wykorzystywane przez globalnych operatorów logistycznych w wielu krajach Europy
                        jak również
                        dystrybutorów globalnych marek oraz producentów dostarczających do odbiorów na całym świecie
                    </article> -->
                    <article class="mb-20 mtbD-30">
                        <h2>Firma</h2>
                        SmarterApps od 2015 roku digitalizuje zarządzanie logistyką mobilną. Najważniejsze kamienie
                        milowe rozwoju
                        spółki:
                    </article>
                </section>
                <!-- Rozwiązania dla dużych i małych firm -->
                <!-- <section class="sectionImage mb-20" id="podMiddImg2"></section> -->
                <ul class="logicList mb-20 mtbD-30">
                    <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon">
                                <div class="year">2015</div>
                                <div class="date">Październik</div>
                            </div>
                        </div>
                        <div class="text">
                            Powołanie spółki SmarterApps
                        </div>
                    </li>
                    <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon">
                                <div class="year">2016</div>
                                <div class="date">Grudzień</div>
                            </div>
                        </div>
                        <div class="text">
                            Koncepcja „sprytnych” rozwiązań technologicznych znajduje potwierdzenie w pierwszym
                            kontrakcie podpisanym
                            z dużym, międzynarodowym klientem.
                        </div>
                    </li>
                    <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon">
                                <div class="year">2017</div>
                                <div class="date">Styczeń</div>
                            </div>
                        </div>
                        <div class="text">
                            Budujemy nowe produkty, jednocześnie poszerzając funkcjonalności istniejących produktów.
                        </div>
                    </li>
                    <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon">
                                <div class="year">2017</div>
                                <div class="date">Sierpień</div>
                            </div>
                        </div>
                        <div class="text">
                            Pierwsze zakończone wdrożenie systemu SmarterPOD.
                        </div>
                    </li>
                    <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon">
                                <div class="year">2018</div>
                                <div class="date">Czerwiec</div>
                            </div>
                        </div>
                        <div class="text">
                            Pierwsza implementacja systemu SmarterPOD do globalnego klienta
                        </div>
                    </li>
                    <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon">
                                <div class="year">2018</div>
                            </div>
                        </div>
                        <div class="text">
                            Pierwsza implementacja SmarterYMS u globalnego klienta, jednocześnie wybrana przez niego
                            jako standard dla całej korporacji.
                        </div>
                    </li>
                    <li class="timeLine">
                        <div class="iconBox">
                            <div class="icon">
                                <div class="year">2019</div>
                            </div>
                        </div>
                        <div class="text">
                            Dynamiczny rozwój oferty produktowej o moduły SmarterUCP, SmarterGATE, WEBBooking
                        </div>
                    </li>
                </ul>
            </section>
            <!-- Stopka -->
            <section class="footertop px-15">
                <div class="desctopContent">
                    <div class="mb-20 mt-10">
                        <b style="font-size: 18px;">Współpracuj z nami</b>
                        <br><br>
                        Daj nam znać jak możemy pomóc twojej firmie. Odpowiemy niezwłocznie.
                    </div>
                    <div class="mb-20">
                        <button class="contactBtn accentBg" (click)="contactUs()">
                            Skontaktuj się z nami
                        </button>
                    </div>
                </div>
            </section>
            <!-- podziel się -->
            <section class="footerMiddle pt-10 px-15">
                <div class="desctopContent">
                    <div class="flex flexItemCenter mb-10">
                        <div class="icon"><img src="assets/m1.svg"></div>
                        <div class="ml-20">Pobierz PDF</div>
                    </div>
                    <div class="flex flexItemCenter mb-10">
                        <div class="icon"><img src="assets/m2.svg"></div>
                        <div class="ml-20">Prześlij znajomemu</div>
                    </div>
                    <div class="flex flexItemCenter backButton">
                        <div class="icon"><img src="assets/m3.svg"></div>
                        <div class="ml-20" (click)="fullpage_api.moveTo('o-firmie', 0);">Powrót</div>
                    </div>
                </div>
            </section>
            <app-footer></app-footer>
        </div>
    </div>
    <!-- Kontakt -->
    <div class="section coverImg" id="screen8">
        <div class="slide">
            <div class="gridEnd desktopHeaderLineBox ">
                <div class="topWrapper">
                    <div id="logoMenu" class="flex flexItemCenter saLogo px-15 pxD-35 ">
                        <div class="logoRotate2 mr-5">
                            <img src="assets/logo_icon.svg">
                        </div>
                        <div class="logoText">
                            <img src="assets/sa_text2.svg">
                        </div>
                    </div>
                    <div id="burgerMenu" class="flexRight px-15 pxD-35 ">
                        <div class="burger" (click)="openMenu()">
                            <div class="line1"></div>
                            <div class="short line2"></div>
                            <div class="line3"></div>
                        </div>
                    </div>
                </div>
                <div class="px-15 desktopPx-35">
                    <small class="hero">SmarterApps</small>
                    <h3 class="mt-10 mb-40">Kontakt</h3>
                    <section>
                        <article class="mb-20 mtbD-30">
                            <h4>Dział handlowy</h4>
                            <div class="icon_contact mb-10">
                                <img class="mr-10" src="assets/icon_mail.svg">
                                business@smarterpod.eu
                            </div>
                            <div class="icon_contact mb-10">
                                <img class="mr-10" src="assets/icon_phone.svg">
                                +48 501 000 355
                            </div>
                        </article>
                        <article class="mb-20 mtbD-30">
                            <h4>Biuro</h4>
                            <div class="icon_contact mb-10">
                                <img class="mr-10" src="assets/icon_mail.svg">
                                office@smarterapps.eu
                            </div>
                            <div class="icon_contact mb-10">
                                <img class="mr-10" src="assets/icon_phone.svg">
                                +48 (22) 290 00 02
                            </div>
                        </article>
                        <article class="mb-20 mtbD-30">
                            <h4>Dane rejestrowe firmy</h4>
                            <div class="mb-5">SmarterApps Sp. z o.o. </div>
                            <div class="mb-5">Ul. Ostrobramska 79 </div>
                            <div class="mb-5">04-175 Warszawa </div>
                            <div class="mb-5">NIP: PL 9522140414</div>
                            <div class="mb-5">KRS: 0000582511 </div>
                        </article>
                        <article class="mt-20 moreArrow" (click)="fullpage_api.moveTo('kontakt', 1);">
                            Polityka prywatności
                            <span><img width="15" height="15" src="assets/arrowN.svg"></span>
                        </article>
                    </section>
                </div>
                <app-footer></app-footer>
            </div>
            <div class="socialShare">
                <div class="fb-share-button" data-href="https://test.smarterapps.eu/" data-layout="button_count"
                    data-size="small">
                    <a target="_blank"
                        href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ftest.smarterapps.eu%2F&amp;src=sdkpreparse"
                        class="fb-xfbml-parse-ignore">
                        <img src="assets/fb.svg">
                    </a>
                </div>
                <div><img src="../../../../assets/in.svg" style="opacity: 0.1;"></div>
                <div><img src="../../../../assets/wapp.svg" style="opacity: 0.1;"></div>
                <div><img src="../../../../assets/msg.svg" style="opacity: 0.1;"></div>
                <div><img src="../../../../assets/icon_mail.svg" style="opacity: 0.1;"></div>
            </div>
        </div>
        <!-- Szablon Polityka prywatności -->
        <div class="slide slidePanel">
            <section class="desctopContent px-15">
                <!-- Nagłówek & menu -->
                <section class="gridWraperLayout mb-10">
                    <div class="logo2">
                        <img src="assets/logo_ba.svg">
                    </div>
                    <div class="burger2" (click)="fullpage_api.moveTo('kontakt', 0);">
                        <div class="line1"></div>
                        <div class="short line2"></div>
                        <div class="line3"></div>
                    </div>
                </section>
                <!-- Wstęp -->
                <section class="headerLine2 mb-20">
                    <h3>Polityka prywatności</h3>
                </section>
                <section>
                    <article class="mb-20">
                        <b>I. Definicje</b>
                        <br><br>
                        Administrator - Smarter Apps Sp. z o.o. z siedzibą w Warszawie, ul. Ostrobramska 79, wpisana do
                        rejestru
                        przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m. st. Warszawy
                        w Warszawie,
                        XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000582511, NIP:
                        9522140414, Regon:
                        362815150, który świadczony usługi drogą elektroniczną oraz przechowuje i uzyskuje dostęp do
                        informacji w
                        urządzeniach Użytkownika.<br><br>
                        Cookies - oznacza dane informatyczne, w szczególności niewielkie pliki tekstowe, zapisywane i
                        przechowywane
                        na urządzeniach za pośrednictwem których Użytkownik korzysta ze stron internetowych
                        Serwisu.<br><br>
                        Cookies Administratora - oznacza Cookies zamieszczane przez Administratora, związane ze
                        świadczeniem usług
                        droga elektroniczną przez Administratora za pośrednictwem Serwisu.<br><br>
                        Cookies Zewnętrzne - oznacza Cookies zamieszczane przez partnerów Administratora, za
                        pośrednictwem strony
                        internetowej Serwisu.<br><br>
                        Serwis - oznacza stronę internetową lub aplikację, pod którą Administrator prowadzi serwis
                        internetowy,
                        działającą w domenie smarterapps.eu.<br><br>
                        Urządzenie - oznacza elektroniczne urządzenie za pośrednictwem, którego Użytkownik uzyskuje
                        dostęp do
                        Serwisu.<br><br>
                        Użytkownik - oznacza podmiot, na rzecz którego zgodnie z Regulaminem i przepisami prawa mogą być
                        świadczone
                        usługi drogą elektroniczną lub z którym zawarta może być Umowa o świadczenie usług drogą
                        elektroniczną.<br><br>

                        <b>II. Rodzaje wykorzystywanych Cookies</b>
                        <br><br>
                        Stosowane przez Administratora Cookies są bezpieczne dla Urządzenia Użytkownika. W szczególności
                        tą drogą
                        nie jest możliwe przedostanie się do Urządzeń Użytkowników wirusów lub innego niechcianego
                        oprogramowania
                        lub oprogramowania złośliwego. Pliki te pozwalają zidentyfikować oprogramowanie wykorzystywane
                        przez
                        Użytkownika i dostosować Serwis indywidualnie każdemu Użytkownikowi. Cookies zazwyczaj zawierają
                        nazwę
                        domeny z której pochodzą, czas przechowywania ich na Urządzeniu oraz przypisaną
                        wartość.<br><br>
                        Administrator wykorzystuje dwa typy plików cookies:<br><br>
                        Cookies sesyjne: są przechowywane na Urządzeniu Użytkownika i pozostają tam do momentu
                        zakończenia sesji
                        danej przeglądarki. Zapisane informacje są wówczas trwale usuwane z pamięci Urządzenia.
                        Mechanizm cookies
                        sesyjnych nie pozwala na pobieranie jakichkolwiek danych osobowych ani żadnych informacji
                        poufnych z
                        Urządzenia Użytkownika.<br><br>
                        Cookies trwałe: są przechowywane na Urządzeniu Użytkownika i pozostają tam do momentu ich
                        skasowania.Zakończenie sesji danej przeglądarki lub wyłączenie Urządzenia nie powoduje ich
                        usunięcia z
                        Urządzenia Użytkownika. Mechanizm cookies trwałych nie pozwala na pobieranie jakichkolwiek
                        danych osobowych
                        ani żadnych informacji poufnych z Urządzenia Użytkownika.<br><br>
                        Użytkownik ma możliwość ograniczenia lub wyłączenia dostępu plików cookies do swojego
                        Urządzenia. Wprzypadku
                        skorzystania z tej opcji korzystanie ze Serwisu będzie możliwe, poza funkcjami, które ze swojej
                        natury
                        wymagają plików cookies.<br><br>

                        <b>III. Cele w jakich wykorzystywane są Cookies</b>
                        <br><br>
                        1. Administrator wykorzystuje Cookies Własne w następujących celach:
                        <br>
                        a) Konfiguracji serwisu
                        <br>
                        dostosowania zawartości stron internetowych Serwisu do preferencji Użytkownika oraz
                        optymalizacji
                        korzystania ze stron internetowych Serwisu.
                        rozpoznania urządzenia Użytkownika Serwisu oraz jego lokalizację i odpowiednio wyświetlenia
                        strony
                        internetowej, dostosowanej do jego indywidualnych potrzeb;
                        zapamiętania ustawień wybranych przez Użytkownika i personalizacji interfejsu Użytkownika, np. w
                        zakresie
                        wybranego języka lub regionu, z którego pochodzi Użytkownik,
                        zapamiętania historii odwiedzonych stron w serwisie w celu rekomendacji treści.<br><br>
                        b) Realizacji procesów niezbędnych dla pełnej funkcjonalności stron internetowych
                        <br>
                        dostosowania zawartości stron internetowych Serwisu do preferencji Użytkownika oraz
                        optymalizacji
                        korzystania ze stron internetowych Serwisu. W szczególności pliki te pozwalają rozpoznać
                        podstawowe
                        parametry Urządzenia Użytkownika i odpowiednio wyświetlić stronę internetową, dostosowaną do
                        jego
                        indywidualnych potrzeb.<br><br>
                        c) Zapamiętania lokalizacji użytkownika
                        <br>
                        poprawnej konfiguracji wybranych funkcji Serwisu, umożliwiając w szczególności dostosowanie
                        dostarczanych
                        informacji do Użytkownika z uwzględnieniem jego lokalizacji.<br><br>
                        d) Analiz i badań oraz audytu oglądalności
                        <br>
                        tworzenia anonimowych statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy Serwisu
                        korzystają ze
                        stron internetowych Serwisu, co umożliwia ulepszanie ich struktury i zawartości.<br><br>
                        e) Zapewnienia bezpieczeństwa i niezawodności serwisu.<br><br>


                        2. Administrator usługi wykorzystuje Cookies Zewnętrzne w następujących celach:
                        <br>
                        a) zbierania ogólnych i anonimowych danych statycznych za pośrednictwem narzędzi analitycznych:
                        <br>
                        Google Analytics [administrator cookies: Google Inc z siedzibą w USA]<br><br>
                        b) wykorzystania funkcji interaktywnych w celu popularyzacji serwisu za pomocą serwisów
                        społecznościowych:
                        <br>
                        twitter.com [administrator cookies: Twitter Inc. z siedzibą w USA]<br>
                        plus.google.com [administrator cookies: Google Inc z siedzibą w USA]<br>
                        Facebook.com [administrator cookies: Facebook Inc z siedzibą w USA lub Facebook Ireland z
                        siedzibą w
                        Irlandii]<br>
                        LinkedIn.com [administrator cookies: LinkedIn Ireland Limited z siedzibą w Irlandii]<br><br>

                        <b>IV. Możliwości określenia warunków przechowywania lub uzyskiwania dostępu przez Cookies</b>
                        <br><br>
                        Użytkownik może samodzielnie i w każdym czasie zmienić ustawienia dotyczące plików Cookies,
                        określając
                        warunki ich przechowywania i uzyskiwania dostępu przez pliki Cookies do Urządzenia Użytkownika.
                        Zmiany
                        ustawień, o których mowa w zdaniu poprzednim, Użytkownik może dokonać za pomocą ustawień
                        przeglądarki
                        internetowej lub za pomocą konfiguracji usługi. Ustawienia te mogą zostać zmienione w
                        szczególności w taki
                        sposób, aby blokować automatyczną obsługę plików cookies w ustawieniach przeglądarki
                        internetowej bądź
                        informować o ich każdorazowym zamieszczeniu Cookies na urządzeniu Użytkownika. Szczegółowe
                        informacje o
                        możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania
                        (przeglądarki
                        internetowej).
                        Użytkownik może w każdej chwili usunąć pliki Cookies korzystając z dostępnych funkcji w
                        przeglądarce
                        internetowej, której używa.
                        Ograniczenie stosowania plików Cookies, może wpłynąć na niektóre funkcjonalności dostępne na
                        stronie
                        internetowej Serwisu.<br><br>
                    </article>
                </section>
            </section>
            <app-footer></app-footer>
        </div>
    </div>
</div>
<div class="topMenu" [ngClass]="{'visMenu':showMenu}">
    <ul id="menu" class="menu h-100">
        <div class="flex flexItemCenter spaceBetween pb-10 menuLogo">
            <div class="flex flexItemCenter saLogo">
                <div class="logoRotate3 mr-5">
                    <img src="assets/logo_icon.svg">
                </div>
                <div style="width: 120px;margin-top: 3px;">
                    <img src="assets/sa_text2.svg">
                </div>
            </div>
            <div class="flexRight">
                <div class="burger burgerOpen" (click)="closeMenu()">
                    <div class="line1"></div>
                    <div class="short line2"></div>
                    <div class="line3"></div>
                </div>
            </div>
        </div>
        <li data-menuanchor="home" class="listLinkItem active" (click)="closeMenu()">
            <a class="itemLink" href="#home">
                Strona główna <span><img width="20" height="20" src="assets/arrow2.svg"></span>
            </a>
        </li>
        <li data-menuanchor="pod" class="listLinkItem" (click)="closeMenu()">
            <a class="itemLink" href="#pod">
                SmarterPOD <span><img width="20" height="20" src="assets/arrow2.svg"></span>
            </a>
        </li>
        <li data-menuanchor="yms" class="listLinkItem" (click)="closeMenu()">
            <a class="itemLink" href="#yms">
                SmarterYMS <span><img width="20" height="20" src="assets/arrow2.svg"></span>
            </a>
        </li>
        <li data-menuanchor="operatorzy-logistyczni" class="listLinkItem" (click)="closeMenu()">
            <a class="itemLink" href="#operatorzy-logistyczni">
                Operatorzy logistyczni <span><img width="20" height="20" src="assets/arrow2.svg"></span>
            </a>
        </li>
        <li data-menuanchor="producenci-i-dystrybutorzy" class="listLinkItem" (click)="closeMenu()">
            <a class="itemLink" href="#producenci-i-dystrybutorzy">
                Producenci i dystrybutorzy <span><img width="20" height="20" src="assets/arrow2.svg"></span>
            </a>
        </li>
        <li data-menuanchor="firmy-transportowe" class="listLinkItem" (click)="closeMenu()">
            <a class="itemLink" href="#firmy-transportowe">
                Firmy transportowe <span><img width="20" height="20" src="assets/arrow2.svg"></span>
            </a>
        </li>
        <li data-menuanchor="o-firmie" class="listLinkItem" (click)="closeMenu()">
            <a class="itemLink" href="#o-firmie">
                O firmie <span><img width="20" height="20" src="assets/arrow2.svg"></span>
            </a>
        </li>
        <div class="pt-15 pb-10"
            style="color: #fff;font-size: 12px; font-weight:bold;border-bottom: 1px solid #fff;border-top: 1px solid #fff;display: grid; grid-template-columns: 1fr 1fr;">
            Zmień język na:
            <!-- Social Media -->
            <!-- <div>
            <img class="mr-10" width="20" src="../../../../assets/fb.svg">
            <img class="mr-10" width="20" src="../../../../assets/in.svg">
            <img class="mr-10" width="20" src="../../../../assets/wapp.svg">
            <img class="mr-10" width="20" src="../../../../assets/msg.svg">
            <img class="mr-10" width="20" src="../../../../assets/mail.svg">
            </div> -->
            <div style="display: flex; align-items: center; justify-content: flex-end;">
                <img src="assets/country/en.svg" width="20" alt="country" style="margin-right: 5px;">
                <img src="assets/country/en1.svg" width="20" alt="country" style="margin-right: 5px;">
                <a routerLink="en">English</a>
            </div>
        </div>
        <div data-menuanchor="kontakt" class="listLinkItem" (click)="closeMenu()">
            <a class="itemLink mb-5" href="#kontakt">
                <div class="mb-30 contactDesc">Sprawdź jak możemy zdigitalizować zarządzanie logistyką w twoim
                    biznesie.</div>
                <div class="text-center">
                    <button class="msgBtn mb-20">Skontaktuj się z nami</button>
                </div>
            </a>
        </div>
    </ul>
</div>